import {LoadingView} from "./LoadingView";

import {BlockingData} from "swr/_internal";
import {Data} from "@google-cloud/functions-framework";
import Config = firebase.auth.Config;
import {List} from "postcss/lib/list";
import {DocumentData} from "@tatsuokaniwa/swr-firestore";
const Checked = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="green" className="size-4 h-4">
    <path fillRule="evenodd"
          d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
          clipRule="evenodd"/>
</svg>

const NotChecked = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="purple"
                        className="size-4 h-4">
    <path fillRule="evenodd"
          d="M6.333 4.478A4 4 0 0 0 1 8.25c0 .414.336.75.75.75h3.322c.572.71 1.219 1.356 1.928 1.928v3.322c0 .414.336.75.75.75a4 4 0 0 0 3.772-5.333A10.721 10.721 0 0 0 15 1.75a.75.75 0 0 0-.75-.75c-3.133 0-5.953 1.34-7.917 3.478ZM12 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
          clipRule="evenodd"/>
    <path
        d="M3.902 10.682a.75.75 0 1 0-1.313-.725 4.764 4.764 0 0 0-.469 3.36.75.75 0 0 0 .564.563 4.76 4.76 0 0 0 3.359-.47.75.75 0 1 0-.725-1.312 3.231 3.231 0 0 1-1.81.393 3.232 3.232 0 0 1 .394-1.81Z"/>
</svg>
export function LoadingApotekData(props: {
    regions: {},
    units: {},
    authUsers: BlockingData<Data, Config> extends true ? Data : (Data | undefined),
    dbUsers: DocumentData<{
        firstName: string;
        lastName: string;
        region: string;
        unit: string;
        id: string
    }>[] | string | number | boolean | Object | List
}) {
    return <LoadingView description={<div className={"text-left"}>
        Vent mens vi laster inn og sammenstiller data...<br/>
        <div className={"grid grid-cols-2"}>
            Regioner: {!props.regions ? NotChecked : Checked}
            Apotek: {!props.units ? NotChecked : Checked}
            Registreringer: {!props.authUsers ? NotChecked : Checked}
            Ansatte: {!props.dbUsers ? NotChecked : Checked}
        </div>
    </div>}
    />;
}