import {collection, getFirestore, limit, query} from "firebase/firestore";
import {useCollection} from "@tatsuokaniwa/swr-firestore";

const useUsers = ({resultsLimit}: {resultsLimit?: number}) => {
    const db = getFirestore();

    const imagesRef = collection(db, '/users');
    const q = query(imagesRef, limit(resultsLimit ? resultsLimit : 500));

    return useCollection(q);
}

export default useUsers;

export const useSWRUsers = () => {

    const {data:users, ...rest} = useCollection<{
        firstName: string
        lastName: string
        region: string
        unit: string
        id: string
    }>({
        path: "users",
    });

    return {users, usersById: users?.reduce(function(map, obj) {
            map[obj.id] = obj;
            return map;
        }, {}), ...rest
    }
}
