import * as React from "react";
import {AuthProvider, useFirebaseApp, useInitPerformance} from "reactfire";

import {AuthRouter} from "./AuthRouter";

import "./index.css";

// Import auth directly because most components need it
// Other Firebase libraries can be lazy-loaded as-needed
import {getAuth} from "firebase/auth";


const App = () => {
    const firebaseApp = useFirebaseApp();
    const auth = getAuth(firebaseApp);

    useInitPerformance(
        async (firebaseApp) => {
            const {getPerformance} = await import("firebase/performance");
            return getPerformance(firebaseApp);
        },
        {suspense: false} // false because we don't want to stop render while we wait for perf
    );

    return (

            <AuthProvider sdk={auth}>
                <AuthRouter/>
            </AuthProvider>

    );
};

export default App;
