import {Link, redirect, useParams} from "react-router-dom";

import {useRegions} from "./components/useRegions";
import {useUnits} from "./components/useUnits";

import DataTable from "react-data-table-component";
import {useUsersPerUnit} from "./components/useRegisteredUsers";
import {useState} from "react";
import {deleteUnit, overwriteUnit} from "./lib/db";


interface EditProps {
    onClick?: () => void
}

const Edit = ({onClick}: EditProps) => {
    return <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                stroke="green" className="size-6">
        <path strokeLinecap="round" strokeLinejoin="round"
              d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"/>
    </svg>

}

export const Delete = ({onClick}: EditProps) => {
    return <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                stroke="red" className="size-6">
        <path strokeLinecap="round" strokeLinejoin="round"
              d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"/>
    </svg>

}

export const Save = ({onClick}: EditProps) => {
    return <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                stroke="green" className="size-6 h-8 hover:cursor-pointer">
        <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5"/>
    </svg>
}

export const Cancel = ({onClick}: EditProps) => {
    return <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                stroke="red"
                className="size-6 h-8 hover:cursor-pointer">
        <path strokeLinecap="round" strokeLinejoin="round"
              d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
    </svg>
}

export const Modal = ({visible, children}) => {
    if (!visible) return null
    return <div
        className={"bg-gray-600 bg-opacity-80 fixed w-full h-full flex align-middle items-center content-center z-20 m-0 top-0 left-0"}>
        <div
            className={"relative w-1/2 bg-gray-50 rounded-xl border-gray-300 border min-h-[33vw] flex flex-col mx-auto p-4"}>
            {children}
        </div>
    </div>
}

export const DropdownRegion = ({onChange, regions}) => {
    return <div>
        <form onChange={onChange} className="max-w-sm">
            <label htmlFor="countries" className="block mb-2 text-sm font-medium">Velg region</label>
            <select id="countries"
                    className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                {regions?.map((region) => {
                    return <option value={region.id}>{region.name}</option>
                })}
            </select>
        </form>
    </div>
}

export const DropdownUnit = ({onChange, units, selectedUnit}) => {
    return <div>
        <form onChange={onChange} className="max-w-sm">
            <label htmlFor="countries" className="block mb-2 text-sm font-medium">Velg region</label>
            <select id="countries"
                    className="bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                <option value={""} disabled selected={!selectedUnit}>Velg enhet</option>
                {units?.sort((a, b) => -b.name.localeCompare(a.name)).map((unit) => {
                    return <option selected={unit.id === selectedUnit} value={unit.id}>{unit.name}</option>
                })}
            </select>
        </form>
    </div>
}

export const EditForm = ({unit, unitId, regions, visible, cancelClick}) => {

    const [name, setName] = useState(unit.name);
    const [region, setRegion] = useState(unit.region);
    const [isSuccess, setIsSuccess] = useState(false);

    const updateUnit = () => {
        if (region && name) {
            overwriteUnit(unitId, {name: name, region: region}).then(() => {
                setIsSuccess(true);
            })
        }
    }

    return <Modal visible={visible}><label htmlFor="countries" className="block mb-2 text-sm font-medium">Velg
        navn</label>
        <input value={name}
               onChange={(e) => {
                   setName(e.target.value)
                   setIsSuccess(false);
               }}
               className={"max-w-sm bg-gray-100 p-2 rounded border  border-gray-300 text-gray-900 text-sm"}/>
        <DropdownRegion regions={regions} onChange={(e) => {
            console.log(e)
            setRegion(e.target.value)
            setIsSuccess(false);
        }}/>
        Valgt region: {regions?.find((_region: string) => {
            return _region.id === region
        })?.name}
        {isSuccess && <div className={"w-full bg-green-500 p-3 rounded text-white my-4"}>Enheten er oppdatert</div>}
        <div className={"ml-auto mt-auto flex flex-row"}>
            <Save onClick={updateUnit}/>
            <Cancel onClick={cancelClick}/>
        </div>
    </Modal>
}


const UnitsView = () => {
    const {unitId} = useParams();
    const {units} = useUnits()
    const usersPerUnit = useUsersPerUnit()
    const {regionsAsList, regions} = useRegions();

    const columns = [
        {
            name: 'Etternavn',
            selector: row => row.lastName,
            sortable: true,
            cell: (row) => {
                if (!row.userId) {
                    return row.lastName
                }
                return <Link to={"/admin/users/" + row.userId}>{row.lastName}</Link>
            }

        },
        {
            name: 'Fornavn',
            selector: row => row.firstName,
            cell: (row) => {
                if (!row.userId) {
                    return row.firstName
                }
                return <Link to={"/admin/users/" + row.userId}>{row.firstName}</Link>
            }
        },
        {
            name: 'Er registrert',
            selector: row => row.registeredUser ? "J" : "N",
        },
        {
            name: 'Ansattform',
            selector: row => row.employmentType || row?._row["Ansattform på hovedarbeidsforhold"] || "N/A",
        },
        {
            name: 'Registreringstidspunkt',
            selector: row => row.registeredUser?.metadata?.creationTime,
            sortable: true,
        },

    ];

    const [isEditMode, setEditMode] = useState(false);

    if (!units || !units[unitId]) {
        return <div>loading...</div>
    }


    return <>
        <EditForm unitId={unitId} visible={isEditMode} unit={units[unitId]} regions={regionsAsList} cancelClick={() => {
            setEditMode(false)
        }}/>
        <div className={"flex flex-col relative"}>

            <div className={"absolute right-0 top-0 flex gap-2"}>
                <div className={"h-8 w-8 min-w-10"}><Delete onClick={() => {
                    if (confirm("Vil du slette denne enheten?") === true) {
                        deleteUnit(unitId).then(() => {
                            alert("Slettet")
                            setTimeout(() => {
                                redirect("/admin/units/")
                            }, 3000)

                        })
                    }
                }}/></div>

                <div className={"h-8 w-8 min-w-10"}>{!isEditMode ? <Edit onClick={() => {
                    setEditMode(true)
                }}/> : <><Cancel onClick={() => {
                    setEditMode(false)
                }}/></>}</div>
            </div>
            <h1 className={"text-lg"}>Apotek / Team: {units[unitId].name}</h1>
            <h1 className={"text-4xl mt-1"}>{units[unitId].name}</h1>
            <h1 className={"text-lg mt-3"}>Tilhørende region/avdeling:</h1>
            <Link to={"/admin/regions/"+units[unitId].region}><h1 className={"text-3xl mt-1 mb-4"}>{regions && regions[units[unitId].region]}</h1></Link>
            <DataTable
                columns={columns}
                data={usersPerUnit && usersPerUnit[unitId].users || []}
                persistTableHead
                pagination
                paginationPerPage={50}
                paginationRowsPerPageOptions={[25, 50]}

                conditionalRowStyles={[
                    {
                        when: row => !!row.registeredUser,
                        classNames: ["bg-green-200"],
                        style: {
                            backgroundColor: 'rgba(185,237,157,0.63)',
                            color: 'black',
                        },
                    },
                ]}
            />
        </div>
    </>
}

export default UnitsView
