import {utils, writeFile} from 'xlsx';


const Download = ({users}: {
    users: {
        firstName: string,
        lastName: string,
        phoneNumber: string,
        isRegistered: boolean,
        registerTime: Date,
        region: string,
        location: string,
        area: string,
        unit: string
    }[]
}) => {

    console.log("rerendered dl btn")

    const createAndDownload = () => {

        /* generate worksheet and workbook */
        const worksheet = utils.json_to_sheet(users);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, "ansatte");

        /* fix headers */
        utils.sheet_add_aoa(worksheet, [[]], {origin: "A1"});

        /* calculate column width */
        //const max_width = rows.reduce((w, r) => Math.max(w, r.name.length), 10);
        // worksheet["!cols"] = [ { wch: max_width } ];

        /* create an XLSX file and try to save to Presidents.xlsx */
        writeFile(workbook, new Date(Date.now()).toISOString() + "-medarbeiderdna.xlsx", {compression: true});
    }


    return (<>

            <div
                className={"bg-green-500 rounded py-1.5 px-2 text-white ml-auto hover:cursor-pointer hover:bg-green-600"}
                onClick={() => {
                    createAndDownload()
                }}>Eksporter til excel</div>
        </>
    )

}

export default Download;