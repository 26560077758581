import * as React from 'react';
import logo from '../assets/logo.svg';
import {useNavigate} from "react-router-dom";
import {getAuth} from "firebase/auth";
import {useFirebaseApp} from "reactfire";
import {collection, doc, getFirestore, limit, orderBy, query, setDoc} from "firebase/firestore";
import {useCollection} from "react-firebase-hooks/firestore";
import {useEffect, useState} from "react";
import {transformUrlToBunny} from "./Screenview";


const writeAnswerToDb = (userId: string) => {
    const db = getFirestore();

    const timestamp = Date.now()

    setDoc(doc(db, "pledges", userId,"version","v1"), {
        userId: userId,
        timestamp: timestamp
    });
}
const Checkbox = ({onClick, checked}: { onClick: () => void, checked: boolean }) => {
    return <div class="flex gap-0 w-max float-right -translate-y-2.5" onClick={onClick}>
        <div class="inline-flex items-center">
            <label class="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor="blue">
                <input type="checkbox"
                       class="before:content[''] peer relative h-6 w-6 cursor-pointer appearance-none rounded-md border border-blue-gray-200 bg-gray-100 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-blue-500 checked:bg-blue-500 checked:before:bg-gray-50 hover:before:opacity-10"
                       id="blue" checked={checked}/>
                <span
                    class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
             stroke="currentColor" stroke-width="1">
          <path fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"></path>
        </svg>
      </span>
            </label>
        </div>
    </div>

}
export const Sporreskjema = () => {

    const navigate = useNavigate();

    const firebaseApp = useFirebaseApp();
    const auth = getAuth(firebaseApp);

    const db = getFirestore();
    const imagesRef = collection(db, '/pledges/' + auth.currentUser.uid + '/version');
    const q = query(imagesRef, orderBy("timestamp", 'desc'), limit(25));
    const [data, loading, error] = useCollection(q);

    const [hasPledged, setHasPledged] = useState(false)

    useEffect(() => {
        if (data) {
            data.docs.forEach((doc) => {
                setHasPledged(true)
                setChecked(true)
                setChecked2(true)
                setChecked3(true)
            })
        }
    }, [data]);

    const [checked, setChecked] = React.useState(false);
    const [checked2, setChecked2] = React.useState(false);
    const [checked3, setChecked3] = React.useState(false);

    return (
        <div className="w-screen h-full max-w-[800px] mx-auto flex flex-wrap justify-around">
            <div className="flex flex-col justify-center w-full p-2 ">
                <div
                className={"absolute top-0 right-0 m-4 p-1 border-white border-[2px] rounded-lg"}
                    onClick={() => {
                    auth.signOut();
                    navigate("/")
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20">
                        <path fill="currentColor" fill-rule="evenodd"
                              d="M3 3a1 1 0 0 0-1 1v12a1 1 0 1 0 2 0V4a1 1 0 0 0-1-1m10.293 9.293a1 1 0 0 0 1.414 1.414l3-3a1 1 0 0 0 0-1.414l-3-3a1 1 0 1 0-1.414 1.414L14.586 9H7a1 1 0 1 0 0 2h7.586z"
                              clip-rule="evenodd"/>
                    </svg>
                </div>
                <img
                    className=" mx-auto py-4 mb-8"
                    alt="Header logo"
                    src={logo}
                />
                <div className="flex gap-4 flex-col p-6">
                    <h1 className="text-center text-3xl mb-4 uppercase">Bli med å bety noe</h1>
                    <div className="bg-blue-200 rounded-xl text-xl gap-0 w-full py-5 px-6 flex flex-col text-black">
                        <div>Jeg er engasjert <Checkbox checked={checked} onClick={() => setChecked(!checked)}/></div>
                        <div>Jeg er pålitelig <Checkbox checked={checked2} onClick={() => setChecked2(!checked2)}/>
                        </div>
                        <div>Jeg bryr meg <Checkbox checked={checked3} onClick={() => setChecked3(!checked3)}/></div>

                        <div>
                            {!hasPledged && <button
                                onClick={() => {
                                    if (checked && checked2 && checked3) {
                                        navigate("/selfie")
                                    }
                                    writeAnswerToDb(auth.currentUser.uid)
                                }}
                                className={
                                    checked && checked2 && checked3 ?
                                        "float-right rounded-xl text-black outline-2 border-green-400 bg-[#57b439] text-xl py-2 px-6 text-white" :
                                        "float-right rounded-xl text-gray-400 outline-2 border-gray-300 bg-gray-200 text-xl py-2 px-6"
                                }>
                                Neste
                            </button>}


                        </div>

                    </div>
                </div>
                {hasPledged && <div className={"grid grid-cols-2 px-6 gap-2"}><button
                    onClick={() => {
                        if (checked && checked2 && checked3) {
                            navigate("/dna")
                        }
                    }}
                    className={
                            "float-right rounded-xl text-black outline-2 border-green-400 bg-[#57b439] text-xl py-2 px-6 text-white"
                    }>
                    Se DNA
                </button><button
                    onClick={() => {
                        if (checked && checked2 && checked3) {
                            navigate("/selfie")
                        }
                    }}
                    className={

                            "float-right rounded-xl text-black outline-2 border-green-400 bg-[#57b439] text-xl py-2 px-6 text-white"
                    }>
                    Ta ny selfie
                </button></div>}
            </div>
        </div>

    );
};


export default Sporreskjema
