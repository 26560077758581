import {useCollection} from "@tatsuokaniwa/swr-firestore";
import {orderBy} from "firebase/firestore";

export const useRegions = () => {

    const {data: regions, ...rest} = useCollection<{ name: string, area: string, location: string }>({
        path: "region",
    });


    return {
        regionsAsList: regions,
        regions: regions?.reduce(function (map, obj) {
            map[obj.id] = obj.name;
            return map;
        }, {}), ...rest
    }
}
