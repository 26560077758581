import * as React from "react";
import {Suspense, useEffect, useRef, useState} from "react";

import {Canvas, extend} from "@react-three/fiber";
import {Html, OrbitControls, PerspectiveCamera, useProgress} from "@react-three/drei";
import {UnrealBloomPass} from 'three-stdlib'
import {OutputPass} from 'three/examples/jsm/postprocessing/OutputPass'
import {collection, getFirestore, limit, orderBy, query} from "firebase/firestore";
import {useCollection} from 'react-firebase-hooks/firestore';
import {getRandomInt, transformUrlToBunny} from "./Screenview";
import {useAuth} from "reactfire";
import Web3DDNAFull from "../components/Web3DDNAFull";


//const colors = new Array(size).fill().map(() => ["#000011","#044d35","#05165b","#02199b","#220e57"][Math.floor(Math.random() * 5)])

extend({UnrealBloomPass, OutputPass})


function Loader() {
    const {progress} = useProgress()
    return <Html center className={"w-screen text-center"}>
        <div className={"w-screen"}>{progress}% av DNA-et er laget</div>
    </Html>
}


export const DNAScreen = () => {
    const db = getFirestore();
    const cameraRef = useRef()

    const [myImage, setMyImage] = useState()
    const imagesRef = collection(db, '/images');
    const q = query(imagesRef, orderBy("timestamp", 'desc'), limit(2000));
    const [data, loading, error] = useCollection(q);

    const auth = useAuth()

    const [images, setImages] = useState([])
    const [allImages, setAllImages] = useState([])


    useEffect(() => {
        if (data) {
            var _allNewImagesFromDB = []
            data.docs.forEach((doc) => {
                    _allNewImagesFromDB.push(transformUrlToBunny(doc.data().imageUrl, "150"))
                })

            setAllImages(_allNewImagesFromDB)
            setImages(_allNewImagesFromDB.slice(0,697))
            console.log(_allNewImagesFromDB.length)
            console.log(allImages.length)
            }
    }, [data]);

    const loadRandomImage = () => {
        if (images.length > 100) {

            const randomImage = images[getRandomInt(images.length)]
            setImages((_images) => {

                const randomIndex = getRandomInt(_images.length)
                return [..._images.slice(0,randomIndex), transformUrlToBunny(randomImage, "150"), ..._images.slice(randomIndex+1)]
            })
        }
    }


    useEffect(() => {
        if (images.length > 100) {

            setTimeout(() => {

                setInterval(() => {

                    loadRandomImage()

                }, 1000)

            }, 10000)

        }


    }, [allImages]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [data])

    console.log({images, allImages})

    if (!data) {
        return <div className="flex flex-col justify-center w-full text-[#737373] min-h-screen text-center">Henter
            bilder av kolleagene dine...</div>
    }

    return (
        <div className="flex flex-col justify-center w-full text-[#737373] min-h-screen">

            <div className="p-2 absolute w-full top-0 left-0 text-md">
                <div className="rounded bg-green-900 p-4 text-white text-md opacity-70">

                    Zoom og dra med to fingre, snurr DNA-et med én finger.
                </div>
            </div>
            <Canvas className="absolute top-0 left-0 h-[100vh] z-100 w-full"
                    style={{zIndex: 3, height: "100vh"}}>
                <Suspense fallback={<Loader/>}>
                    <Web3DDNAFull
                        freeForAll={true}
                        mainImage={myImage || "https://apotek1-dna-images.b-cdn.net/images%2FScreenshot%202024-02-07%20at%2001.42.24.png?alt=media&width=500&crop_gravity=center&aspect_ratio=1000:999"}
                        animate={false}
                        useDefaultCamera={true}
                        //images={data ? data.docs.map((doc) => doc.data().url) : ["https://apotek1-dna-images.b-cdn.net/images%2FScreenshot%202024-02-07%20at%2001.42.24.png?alt=media&width=500&crop_gravity=center&aspect_ratio=1000:999"]}
                        images={images}
                        className={"min-w-full min-h-full object-cover"}/>
                    <ambientLight intensity={1.5}/>
                    <OrbitControls autoRotate={false}
                                   enableRotate
                                   enablePan={true}
                                   enableZoom={true}
                                   reverseOrbit={false}
                                   enableDamping={false}
                                   rotateSpeed={0.5}
                                   maxPolarAngle={Math.PI/2}
                                   minPolarAngle={Math.PI/2}
                                   dampingFactor={0.8}
                        //position={[123.964, 13.46, 0.637]}
                        //target={[123.964, 13.46, 0]}
                    />
                    <PerspectiveCamera
                        makeDefault={true}
                        ref={cameraRef}
                        far={1000}
                        near={0.1}
                        fov={35}
                        position={[-50, 0, 0]}
                        rotation={[0, 0, 0]}
                    />
                    {/*<RoundedBox />*/}
                </Suspense>
            </Canvas>
        </div>
    );
};


export default DNAScreen
