import React, {Suspense, useEffect, useRef, useState} from "react";
import {PerspectiveCamera, useAnimations, useGLTF} from "@react-three/drei";
import {AnimationMixer, DoubleSide, LoopRepeat, MathUtils, SRGBColorSpace, TextureLoader} from "three";
import {LoopOnce} from "three/src/constants";
import {TextureLoadedImage, TexturizedMesh} from "./Web3DDNA";
import {ErrorBoundary} from "@sentry/react";
import {useFrame} from "@react-three/fiber";
import {Stats} from '@react-three/drei'
import {useSearchParams} from "react-router-dom";

const logoImg = "https://apotek1-dna-images.b-cdn.net/images%2FScreenshot%202024-02-07%20at%2001.42.24.png?alt=media&width=500&crop_gravity=center&aspect_ratio=1000:999"

const _images = Array.apply(null, Array(20))
    .map(() => "https://apotek1-dna-images.b-cdn.net/images%2FScreenshot%202024-02-07%20at%2001.42.24.png?alt=media&width=500&crop_gravity=center&aspect_ratio=1000:999");


export function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

const MeshNumbered = ({imageUrl, nodes, meshName}: { imageUrl: string, nodes: any, meshName: string }) => {

    const [url, setUrl] = useState(logoImg)

    useEffect(() => {
        setTimeout(() => {
            setUrl(imageUrl)
        }, getRandomInt(10000))

    }, [imageUrl]);

    return <mesh
        name={meshName}
        geometry={nodes[meshName]?.geometry}
    >
        <Suspense>
            <TexturizedMesh image={url}/>
        </Suspense>
    </mesh>
}

export function Model({
                          mainImage,
                          className,
                          screenState,
                          screenStateTimestamp,
                          images = _images,
                          animate = true,
                          useDefaultCamera = true,
                          freeForAll = false,
                          gltfFile = "/dna_for_the_app_v5.glb",
                          debug = false,
                          ...props
                      }: {
    mainImage: string,
    className: string,
    screenState: string,
    screenStateTimestamp: number,
    animate: boolean,
    useDefaultCamera: boolean,
    images: string[],
    gltfFile?: string,
    freeForAll: boolean,
    debug: boolean
}) {
    const group = useRef();
    const {nodes, materials, animations, scene, cameras} = useGLTF(gltfFile);
    const {actions, mixer} = useAnimations(animations, group);
    const img = "https://apotek1-dna-images.b-cdn.net/images%2FScreenshot%202024-02-07%20at%2001.42.24.png?alt=media&width=500&crop_gravity=center&aspect_ratio=1000:999"
    const texture = TextureLoadedImage(img);
    // const mainImageTexture = useLoader(TextureLoader, mainImage);
    const mainImageTexture = TextureLoadedImage(mainImage);
    const textureLoader = new TextureLoader();


    const [imageTextures, setImageTextures] = useState(images.slice(0, 97)
        .map((element) => {
            return <TexturizedMesh image={element}/>
        }))

    const dnaRef = useRef()
    const cameraRef = useRef()


    useEffect(() => {
        mixer.addEventListener('finished', function (e) {
            console.log("mixed finished event")

            if (!actions.camera_action_v1.isRunning()) {
                //actions.dna_action_rotation.fadeIn(1)
                console.log("start rotation")
                // actions.dna_action_rotation.play()
                // actions.dna_action_rotation.fadeIn(1)
                actions.camera_action_v1.crossFadeTo(actions.dna_action_rotation, 1, false)
                setTimeout(() => {
                    actions.camera_action_v1.stop()
                    actions.dna_action_rotation.play()
                    actions.dna_action_rotation.fadeIn(1)
                }, 1000)

            } else {
                actions.dna_action_rotation.fadeOut(1)
                console.log("stopping rotation")
/*
                setTimeout(() => {
                    //actions.dna_action_rotation.stop()
                }, 1000)
*/

            }
        }); // properties of e: type, action and direction

    }, []);

    const cameraFovDegreeNear = 57
    const cameraFovDegreeDefault = 17
    const cameraFovDegreeFar = 35

    const [cameraFovDegree, setCameraFovDegree] = useState(cameraFovDegreeDefault)

    // current cameraFOV
    //var cameraFovDegree = cameraFovDegreeNear

    useFrame((state, delta, frame) => {
        if (actions.camera_action_v1.isRunning() && cameraFovDegree < cameraFovDegreeNear) {
            // camera fov when inside the camera animation
            setCameraFovDegree(MathUtils.lerp(cameraFovDegree, cameraFovDegreeNear, 0.002))
        } else if (screenState === "dontShowDNA" && screenStateTimestamp + 6000 > new Date().getTime()) {
            // camera fov when in zoom out
            setCameraFovDegree(MathUtils.lerp(cameraFovDegree, cameraFovDegreeFar, 0.1))
        } else {
            // camera fov when we are in default mode
            // setCameraFovDegree(MathUtils.lerp(cameraFovDegree, cameraFovDegreeDefault, 0.1))
        }
    })

    useEffect(() => {
        console.log({actions})

        setTimeout(() => {
            // actions.camera_action_v1.setLoop(LoopRepeat, 0)
            actions.camera_action_v1.setEffectiveTimeScale(0.4)
            // actions.camera_action_v1.play()
            //actions.dna_action_still.play()
            actions.dna_action_rotation.setEffectiveTimeScale(0.5)
            // actions.dna_action_rotation.play()

        }, 100)


    }, []);

    var timeoutreference = undefined

    useEffect(() => {
        console.log({screenState})
        if (screenState === "dontShowDNA" && (screenStateTimestamp + 6000) > new Date().getTime()) {
            console.log("dont showDNA? zoom out")
            // actions.camera_action_v1.fadeOut(1)
            actions.dna_action_rotation.crossFadeFrom(actions.camera_action_v1, 1, false)

            // actions.dna_action_rotation.fadeIn(1)
            // actions.dna_action_rotation.play()
            clearTimeout(timeoutreference)
            timeoutreference = setTimeout(() => {
                actions.camera_action_v1.play()
                actions.camera_action_v1.crossFadeFrom(actions.dna_action_rotation, 1, false)
            }, 6000)

            setTimeout(() => {
                actions.camera_action_v1.stop()
            }, 1000)
        } else {
            console.log("showDNA?")
            if (!actions.camera_action_v1.isRunning()) {
                //actions.dna_action_rotation.fadeOut(1)
                // actions.camera_action_v1.setLoop(LoopOnce, 0)
                actions.camera_action_v1.play()
                actions.camera_action_v1.crossFadeFrom(actions.dna_action_rotation, 1, false)
                setTimeout(() => {
                    actions.dna_action_rotation.stop()
                }, 1000)
            }
        }
    }, [screenState, screenStateTimestamp]);

    /*    useFrame((state, delta) => {
        mixer.update(delta);
    });*/

    /*    useEffect(() => {
            if (dnaRef.current && dnaRef.current.geometry) {
                dnaRef.current.geometry.center()
            }
        }, [dnaRef]);

        console.log({helper})*/

    return (
        <group encoding={SRGBColorSpace} ref={group} {...props} dispose={null}>
            <group name="Scene">
                <group ref={dnaRef} encoding={SRGBColorSpace} name="dna" scale={1}
                    //position={[126.789, -0.958, 0]}
                    //position={[3.5, -17, 0]}
                    //position={freeForAll ? [3.5, -17, 0] : [126.789, -0.958, 0]}
                       position={[-3.389, 0, 0]}
                >
                    <mesh
                        name="main_picture"
                        geometry={nodes.main_picture.geometry}
                        //position={[126.789, -0.958, 0]}
                        //position={[0, 0, 0]}
                        //position={freeForAll ? [3.5, -17, 0] : [126.789, -0.958, 0]}
                        position={[3.389, 0, 0]}


                    >
                        <meshStandardMaterial side={DoubleSide} map={mainImageTexture}/>
                    </mesh>

                    <mesh
                        name="Circle002"
                        geometry={nodes.Circle002.geometry}
                        material={materials.digital_back}
                    />
                    <mesh
                        name="Circle002_1"
                        encoding={SRGBColorSpace}
                        geometry={nodes.Circle002_1.geometry}
                        material={materials.green_neon}
                    >
                        {/*<meshStandardMaterial side={DoubleSide} map={materials.green_neon}/>*/}
                    </mesh>
                    <mesh
                        name="Circle002_2"
                        encoding={SRGBColorSpace}
                        geometry={nodes.Circle002_2.geometry}
                        material={materials.blue_neon}
                    />
                    <mesh
                        name="Circle002_3"
                        encoding={SRGBColorSpace}
                        geometry={nodes.Circle002_3.geometry}
                        material={materials.circle_blue}
                    />
                    <mesh
                        name="Circle002_4"
                        encoding={SRGBColorSpace}
                        geometry={nodes.Circle002_4.geometry}
                        material={materials.circle_green}
                    />
                    <mesh
                        name="Circle002_5"


                        geometry={nodes.Circle002_5.geometry}
                        material={materials["circle_green_jeg-bryr-meg"]}
                    />
                    <mesh
                        name="Circle002_6"


                        geometry={nodes.Circle002_6.geometry}
                        material={materials["circle_green_jeg-er-engasjert"]}
                    />
                    <mesh
                        name="Circle002_7"


                        geometry={nodes.Circle002_7.geometry}
                        material={materials["circle_green_jeg-er-pålitelig"]}
                    />
                    <mesh
                        name="Circle002_8"


                        geometry={nodes.Circle002_8.geometry}
                        material={materials["circle_blue_jeg-bryr-meg"]}
                    />
                    <mesh
                        name="Circle002_9"


                        geometry={nodes.Circle002_9.geometry}
                        material={materials["circle_blue_jeg-er-engasjert"]}
                    />
                    <mesh
                        name="Circle002_10"


                        geometry={nodes.Circle002_10.geometry}
                        material={materials["circle_blue_jeg-er-pålitelig"]}
                    />


                    {/*                    <group name="cam_track"/>
                    <group name="Empty" position={[-0.244, 1.166, -1.05]} rotation={[-2.306, -0.151, -2.037]}>
                        <PerspectiveCamera
                            name="Camera"
                            makeDefault={true}
              /*              far={1000}
                            near={0.1}
                            fov={39.598}
                            position={[0, 3.999, 0]}
                            rotation={[-Math.PI / 2, 0, -Math.PI]}
                        />
                        </group>*/}


                    <ErrorBoundary onError={() => {
                    }}>
                        {images.slice(0).map((imageUrl, index) => {
                            const meshName = "Circle002_" + (index + 11)
                            //return <MeshNumbered meshName={meshName} nodes={nodes} imageUrl={images[meshNumber+106]} />
                            return <ErrorBoundary onError={() => {
                            }}>
                                <mesh
                                    name={meshName}
                                    geometry={nodes.Circle002_15.geometry}
                                >
                                    <MeshNumbered nodes={nodes} meshName={meshName} imageUrl={images[index]}/>
                                </mesh>
                            </ErrorBoundary>
                        })}
                    </ErrorBoundary>

                </group>
                <group position={[-3, 0, 0]} name="cam_track"/>
                <group name="Empty"
                       position={[-3.553, 17.203, -42.531]}
                    //position={[0, 17.203, -42.531]}
                       rotation={[-1.565, 0, 0.006]}


                    // old
                    //position={[-0.244, 1.166, -1.05]}
                    //rotation={[-2.306, -0.151, -2.037]}
                >
                    <PerspectiveCamera
                        name="Camera"
                        ref={cameraRef}
                        makeDefault={true}
                        far={1000}
                        near={0.1}
                        fov={cameraFovDegree}
                        position={[0, 3.999, 0]}
                        rotation={[-Math.PI / 2, 0, -Math.PI]}
                    />
                </group>
            </group>
            {debug && <Stats/>}

        </group>
    );
}

useGLTF.preload("/dna_for_the_app_v5.glb");

export default Model
