import * as React from 'react';
import {useEffect, useState} from 'react';
import {getDatabase, onChildChanged, ref as rtRef, ref, set} from "firebase/database";
import image from "../assets/DNA_merke.png?url"
import logo from "../assets/logo_white.svg?url"
import film from "../../public/bg_loop_10-sec_v2.mp4?url"
import blueRing from "../assets/blue_circle_02.png"
import greenRing from "../assets/green_circle_02.png"
import {useParams} from "react-router-dom";

const ButtonPanel = ({}: React.PropsWithChildren<{}>) => {
    const rtDb = getDatabase();
    const { screenId } = useParams();

    const screenDBRef = ref(rtDb, 'screen')

    const [screenState, setScreenState] = useState({
        state: "default",
        balloon1Time: 0,
        balloon2Time: 0,
        balloon3Time: 0,
        balloon4Time: 0,
    })

    const [currentImagesCount, setCurrentImagesCount] = useState(0)

    useEffect(() => {
        onChildChanged(screenDBRef, (snapshot) => {
            const data = snapshot.val();
            setCurrentImagesCount((c) => c + 1)
            console.log({data})

        });
    }, []);

    const setState = (newState) => {
        setScreenState((oldState) =>  {
            console.log({...oldState,
                ...newState})
            set(rtRef(rtDb, 'screen/' + screenId), {
                ...oldState,
                ...newState
            }).then(() => {
                console.log('saved')
            }).catch((err) => {
                console.error({err})
            });

            return {
                ...oldState,
                ...newState
            }
        })

    }

    const playDNA = () => {
        setState({state: "showDNA", stateTimestamp: new Date().getTime()})
    }



    const dontPlayDNA = () => {
        setState({state: "dontShowDNA"})
        setState({state: "dontShowDNA", stateTimestamp: new Date().getTime()})
    }

    const showCustomerMeetings = () => {
        setState({balloon1Time: new Date().getTime()})
    }

    const showMaze = () => {
        setState({balloon2Time: new Date().getTime()})
    }

    const showAmbassadorDegree = () => {
        setState({balloon3Time: new Date().getTime()})
    }


    const showMemberCount = () => {
        setState({balloon4Time: new Date().getTime()})
    }

    return (
        <div style={{touchAction: "none"}} className={"w-full h-full absolute top-0 left-0 z-10 text-white"}>
            <img className={" h-6 absolute bottom-6 right-6 z-10"} src={logo}/>
            <video muted={true} autoPlay={true} loop={true} src={film} playsInline={true}
                   className={"w-full pointer-events-none absolute top-0 left-0 h-screen object-cover "}/>
            <div
                className="flex flex-col relative justify-center mx-auto w-3/4 min-h-screen gap-4 z-[1002] -mt-12">
                <div className={"w-full mb-5 "}>
                    <img className={"w-2/5 mx-auto"} src={image}/>
                </div>
                <div className={"flex flex-row mx-auto gap-12 text-2xl"}>
                    {/*<div onClick={playDNA}
                         className={"h-[33vw] w-[33vw] rounded-full text-center align-middle flex relative"}>
                        <img onClick={playDNA} src={blueRing}
                             className={"w-full h-full absolute top-0 object-cover "}/>
                        <div onClick={playDNA} className={"m-auto z-10"}>Zoom inn</div>
                    </div>*/}
                    <div onClick={dontPlayDNA}
                         className={"h-[33vw] w-[33vw] rounded-full text-center align-middle flex relative"}>
                        <img onClick={dontPlayDNA} src={greenRing}
                             className={"w-full h-full absolute top-0 object-cover"}/>
                        <div onClick={dontPlayDNA} className={"m-auto z-10"}>Zoom ut</div>
                    </div>
                </div>
                <div className={"w-full px-24 text-4xl flex flex-col gap-6 mt-12"}>
                    <div onClick={showCustomerMeetings} className={"w-full text-white flex"}>
                        <img src={greenRing} className={"h-14 mr-6 -translate-y-2"}/>
                        Antall kundemøter hittil i år
                    </div>
                    <div onClick={showMaze} className={"w-full text-white flex"}>
                        <img src={greenRing} className={"h-14 mr-6 -translate-y-2"}/>
                        Antall tilbakemeldinger, Maze
                    </div>
                    <div onClick={showAmbassadorDegree} className={"w-full text-white flex"}>
                        <img src={greenRing} className={"h-14 mr-6 -translate-y-2"}/>
                        Ambassadørgrad
                    </div>
                    <div onClick={showMemberCount} className={"w-full text-white flex"}>
                        <img src={greenRing} className={"h-14 mr-6 -translate-y-2"}/>
                        Antall DNA-medlemmer
                    </div>
                </div>
            </div>

        </div>
    )

};

export default ButtonPanel
