import React from "react";
import ReactDOM from "react-dom/client";

import {getAuth } from "firebase/auth"; // Firebase v9+
import {getFirestore } from "firebase/firestore"; // Firebase v9+
import {FirebaseAppProvider, AuthProvider, useFirebaseApp, DatabaseProvider} from "reactfire";
import { getStorage } from "firebase/storage";

import App from "./App.jsx";
import "./index.css";
import {FirestoreProvider} from "reactfire";
import {Suspense} from "react";
import {StorageProvider} from "reactfire";
import * as Sentry from "@sentry/react";
import {useEffect} from "react";


const firebaseConfig = {
    apiKey: "AIzaSyC06nMCs_JMxj3TKc6c_jKa7A4OltCAfsM",
    authDomain: "apotek1-dna.firebaseapp.com",
    projectId: "apotek1-dna",
    storageBucket: "apotek1-dna.appspot.com",
    messagingSenderId: "345725891061",
    appId: "1:345725891061:web:1def810231675f15fc30bc",
    measurementId: "G-0NLFB327XW",
    databaseURL: "https://apotek1-dna-default-rtdb.europe-west1.firebasedatabase.app/"
};

Sentry.init({
    dsn: "https://997c7b716a465d8ff2fe542af4fc37f3@o4506729993732096.ingest.sentry.io/4506729995501568",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    beforeSend: (event) => {
        if (window.location.hostname === 'localhost') {
            return null;
        }
        return event;
    },
    environment: (window.location.hostname === 'localhost') ? "development" : "production",
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 10% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/yourserver\.io\/api/, /^https:\/\/securetoken\.googleapis\.com/, "medarbeiderdna.no"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


function FirebaseService() {
    const app = useFirebaseApp();
    const firestore = getFirestore(app);
    const storage = getStorage(app);

    const auth = getAuth(app);

/*
    useEffect(() => {
        setInterval(() => {
            try {
                auth.currentUser.getIdToken( true).then(function(idToken) {
                    console.log("new token:")
                    console.log(idToken)
                });
            } catch {
                // pass
            }

        }, 30000)
    }, []);*/


    // Check for dev/test mode however your app tracks that.
    // `process.env.NODE_ENV` is a common React pattern


    return (
        <AuthProvider sdk={auth}>
            <FirestoreProvider sdk={firestore}>
                <StorageProvider sdk={storage}>
                    <Suspense>
                        <App/>
                    </Suspense>
                </StorageProvider>
            </FirestoreProvider>
        </AuthProvider>
    );
}


ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <FirebaseAppProvider firebaseConfig={firebaseConfig}>
            <FirebaseService/>
        </FirebaseAppProvider>
    </React.StrictMode>,
);
