import * as React from 'react';
import {Suspense, useEffect, useMemo, useState} from 'react';
import logo from '../assets/logo.svg';
import {v4 as uuidv4} from 'uuid';
import hevcVideo from '../../public/map_animation_v2.hvc.mp4'
import vp9Video from '../../public/map_animation_v2.vp9.webm'
import avcVideo from '../../public/map_animation_v2.avc.mp4'

import Camera, {FACING_MODES, IMAGE_TYPES} from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

import {getDownloadURL, ref, uploadString} from "firebase/storage";
import {useAuth, useStorage, useUser} from "reactfire";
import {User} from "firebase/auth";
import {collection, doc, getFirestore, limit, orderBy, query, setDoc} from "firebase/firestore";
import Web3DDNA from "../components/Web3DDNA";
import {Canvas} from "@react-three/fiber";
import {Html, OrbitControls, useProgress} from "@react-three/drei";
import {WideButton} from "./Button";
import {getDatabase, ref as rtRef, set} from "firebase/database";
import {Link} from "react-router-dom";
import {useCollection} from "react-firebase-hooks/firestore";
import {transformUrlToBunny} from "./Screenview";


const writeImageToDb = (imageUrl: string, userId: string, imageId: string) => {
    const db = getFirestore();
    const rtDb = getDatabase();

    const timestamp = Date.now()

    setDoc(doc(db, "images", imageId), {
        userId: userId,
        imageUrl: imageUrl,
        timestamp: timestamp
    });

    set(rtRef(rtDb, 'images/' + timestamp), {
        imageUrl: imageUrl,
        timestamp: timestamp,
        imageId: imageId
    }).then(() => {
        console.log('saved?')
    }).catch((err) => {
        console.error({err})
    });


}

const uploadImage = (file, storage, setIsUploading: (value: (((prevState: boolean) => boolean) | boolean)) => void, setProgress: (value: (((prevState: number) => number) | number)) => void, setError: (value: unknown) => void, userId: string) => {

// Create the file metadata
    /** @type {any} */
    const metadata = {
        contentType: 'image/jpeg'
    };

// Upload file and metadata to the object 'images/mountains.jpg'
    const imageId = uuidv4();
    var success = false
    const storageRef = ref(storage, 'images/' + imageId + '.jpg');
    const uploadTask = uploadString(storageRef, file, 'data_url').catch((error) => {
        setError(error.code)
        setIsUploading(false)
    }).then((result) => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(storageRef).then((downloadURL) => {
            console.log('File available at', downloadURL);
            writeImageToDb(downloadURL, userId, imageId)
        });

        setIsUploading(false)
        setProgress(100)
        console.log({result, storageRef})
        // store in database
        success = true
    })
    return uploadTask
}

function Loader() {
    const {progress} = useProgress()
    return <Html center><div className={"w-full"}>{progress} % loaded</div></Html>
}


function CameraComp({callBackWhenSelfieUploaded}) {
    const auth = useAuth();
    const {data: user} = useUser();
    const userId = (user as User)?.uid

    const storage = useStorage();

    const [isUploading, setIsUploading] = useState(false)
    const [progress, setProgress] = useState(0)
    const [error, setError] = useState(null)
    const [previewImage, setPreviewImage] = useState(null)
    const [showFallbackWindows, setShowFallbackWindow] = useState(null)
    const [runAnimation, setRunAnimation] = useState(false)


    const MemoizedCamera = useMemo(() => {
        return
    }, [])

    function handleTakePhoto(dataUri) {
        //uploadImage(dataUri, storage, setIsUploading, setProgress, setError)
    }

    function handleTakePhotoAnimationDone(dataUri: string) {
        setPreviewImage(dataUri)
    }

    return (
        <>
            {showFallbackWindows && !previewImage &&
                <div className="flex w-full h-full rounded-full bg-gray-300 text-center content-center align-middle p-6">

                    <label className="fallback-upload-button w-screen text-center content-center align-middle pt-[30%]" htmlFor="myFileInput">
                        Klikk for å ta en selfie!
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                             stroke="currentColor" className="w-12 h-12 mx-auto">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z"/>
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z"/>
                        </svg>

                    </label>
                </div>
            }
            <div className="absolute top-0 hidden">
                {error && "Vi fikk ikke tilgang til kameraet ditt direkte, men trykk på ikonet over for å ta en selfie."}
                {progress !== 0 && progress}
            </div>

            {previewImage &&
                <img className={"preview-image " + (runAnimation ? "hide-preview" : "")} src={previewImage}/>}


            {!error && !previewImage && !isUploading &&
                <Camera

                    idealResolution={{width: 480, height: 480}}
                    isFullscreen={false}
                    idealFacingMode={FACING_MODES.USER}
                    isDisplayStartCameraError={true}
                    imageType={IMAGE_TYPES.JPG}
                    onCameraStart={() => {
                        setInterval(() => {
                            try {
                                document.querySelector('.react-html5-camera-photo video').style.padding = "10px"
                                document.querySelector('.react-html5-camera-photo video').style.opacity = "1"
                            } catch (err) {}
                        }, 500)
                    }}
                    onCameraError={() => {
                        setShowFallbackWindow(true)
                    }}
                    onTakePhotoAnimationDone={(dataUri) => {
                        handleTakePhotoAnimationDone(dataUri);
                    }}
                    onTakePhoto={(dataUri) => {
                        handleTakePhoto(dataUri);
                    }}
                />}

            {!previewImage &&
                <input id={"myFileInput"} style={{color: "rgba(0,0,0,0)"}} className={"w-1/2 ml-4 mt-16"} onChange={(event) => {
                    function setBase64Image(file) {
                        var image: string | ArrayBuffer = undefined
                        var reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = function () {
                            //console.log(reader.result);
                            setPreviewImage(reader.result)
                        };
                        reader.onerror = function (error) {
                            console.log('Error: ', error);
                        };
                        console.log({image})
                        return image
                    }

                    if (event.currentTarget?.files.length > 0) {
                        setBase64Image(event.currentTarget?.files[0])
                    }

                }} accept="image/*" type="file"/>}
            {previewImage && <div className="flex flex-row gap-2 pt-4">
                <button
                    onClick={() => {
                        setPreviewImage(null)
                    }}
                    className=" inline-flex items-center content-center w-2/5 ml-auto text-white font-bold bg-[#23A6F0] rounded p-2  box-border gap-3">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                         stroke="currentColor" className="w-6 h-6 ml-auto">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"/>
                    </svg>
                    <div className="mr-auto">
                        Ta nytt bilde
                    </div>


                </button>
                <button
                    onClick={() => {
                        uploadImage(previewImage, storage, setIsUploading, setProgress, setError, userId).then(() => {
                                setRunAnimation(true)
                                callBackWhenSelfieUploaded(previewImage)
                            })
                    }}
                    className=" inline-flex items-center content-center w-2/5 mr-auto text-white font-bold bg-[#23A6F0] rounded p-2  box-border gap-2">
                    <div className="ml-auto">
                        Send inn!
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                         stroke="currentColor" className="w-6 h-6 mr-auto">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"/>
                    </svg>
                </button>
            </div>
            }

        </>
    );
}

export const Selfie = () => {
    const [displayAnimation, setDisplayAnimation] = useState(false)
    const [show3d, setShow3d] = useState(false)
    const [videoIsDone, setVideoIsDone] = useState(false)
    const [videoIsReady, setVideoIsReady] = useState(false)
    const [showVideo, setShowVideo] = useState(false)
    const [previewImage, setPreviewImage] = useState("")
    const [allowOrbiting, setAllowOrbiting] = useState(false)
    const [showCloseVideoButton, setShowCloseVideoButton] = useState(false)

    const db = getFirestore();
    const imagesRef = collection(db, '/images');
    const q = query(imagesRef, orderBy("timestamp", 'desc'), limit(25));
    const [data, loading, error] = useCollection(q);

    //var images = []
    const [images, setImages] = useState<string[]>([])

    useEffect(() => {
        if (data) {
            data.docs.forEach((doc) => {
                //images.push(transformUrlToBunny(doc.data().imageUrl, "75"))
                setImages((images) => {return [...images, transformUrlToBunny(doc.data().imageUrl, "75")]})
            })
        }
    }, [data]);

    const callBackWhenSelfieUploaded = (image) => {
        setPreviewImage(image)
        setTimeout(() => {
            setDisplayAnimation(true)

        }, 100)
        setTimeout(() => {
            setShow3d(true)
        }, 110)
        setTimeout(() => {
            setShowVideo(true)
        }, 13000)
    }


    return (
        <div className="w-screen max-w-[500px] mx-auto flex flex-wrap h-screen overflow-hidden">
            {displayAnimation && <>
                <div style={{opacity: show3d ? 1 : 0}} className={"absolute top-0 w-screen max-w-[500px] h-screen z-10 fade-1s"}>
                    <Canvas className="absolute top-0 left-0  z-100 w-full h-full bg-blue-950" style={{zIndex: 3}}>
                        <Suspense fallback={<Loader/>}>
                            <Web3DDNA mainImage={previewImage} scale={1} animate={!allowOrbiting} images={[...images, ...images, ...images, ...images]}
                                      className={"min-w-full min-h-full object-cover"}/>
                            <ambientLight intensity={1.5}/>
                            {allowOrbiting &&
                                <OrbitControls autoRotate={false} enableRotate position={[123.964, 13.46, 0.637]}
                                               target={[123.964, 13.46, 0]}/>}
                        </Suspense>
                    </Canvas>
                    {showVideo && <>
                        <video onLoadedData={() => {
                            setVideoIsReady(true)
                        }}
                               onEnded={() => {
                                   setVideoIsDone(true)
                                   setTimeout(() => {
                                       setShowCloseVideoButton(true)
                                   }, [150])

                               }}
                               style={{opacity: videoIsReady ? 1 : 0, zIndex: 100}} controls={false} playsInline={true}
                               autoPlay muted loop={false}
                               className={"object-cover min-w-full min-h-full absolute top-0 left-0 z-11"}>
                            <source src={vp9Video} type='video/webm;codecs="vp9, vorbis"'/>
                            <source src={hevcVideo} type='video/mp4;codecs="hevc"'/>
                            <source src={avcVideo} type='video/mp4'/>
                        </video>
                        {videoIsDone && <Link to={"/dna"}><WideButton
                            className={"absolute bottom-[10%] right-[15%] z-[101] !w-auto fade-1s " + (showCloseVideoButton ? "opacity-100" : "opacity-0")}
                            label={"Se DNA-et"}
                            /*onClick={() => {
                                setShowVideo(false)
                                setAllowOrbiting(true)
                            }}*/
                            id={"closevideo"}
                            buttonType={"button"}

                        /></Link>}
                    </>
                    }

                </div>
            </>}
            <div className="flex flex-col w-full text-[#737373]">
                <img
                    className="mx-auto py-4 mb-8 mt-8"
                    alt="Header logo"
                    src={logo}
                />
                {/* <div className="p-4">
                Herlig! Takk for svaret! Vi elsker at du er med på laget! Ta en selfie og bli synlig i Apotek1s DNA.
            </div>*/}
                <div className="flex gap-4 flex-col">
                    <div className="w-full relative h-[100vw] max-h-[500px] object-cover">
                        <div className="object-cover h-full ">
                            <CameraComp callBackWhenSelfieUploaded={callBackWhenSelfieUploaded}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default Selfie
