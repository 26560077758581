import {useCollection} from "@tatsuokaniwa/swr-firestore";

export const useAreas = () => {
    const {data: areas, ...rest} = useCollection<{ name: string }>({
        path: "areas",
    });


    return {
        areasAsList: areas,
        areas: areas?.reduce(function (map, obj) {
            map[obj.id] = obj.name;
            return map;
        }, {}), ...rest
    }
}
