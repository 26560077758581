import React, {useEffect, useRef, useState,Suspense} from "react";
import {useLoader} from '@react-three/fiber'
import {PerspectiveCamera, useAnimations, useGLTF, useHelper} from "@react-three/drei";
import {ArrowHelper, AxesHelper, BoxHelper, DoubleSide, SRGBColorSpace, TextureLoader} from "three";
import {LoopOnce} from "three/src/constants";

export const TextureLoadedImage = (image: string) => {
    const mainImageTexture = useLoader(TextureLoader, image);
    return mainImageTexture
}


export const FallbackMesh = () => {
    const texture = useLoader(TextureLoader, "https://apotek1-dna-images.b-cdn.net/images%2FScreenshot%202024-02-07%20at%2001.42.24.png?alt=media&width=500&crop_gravity=center&aspect_ratio=1000:999");
    return <meshStandardMaterial side={DoubleSide} color={"#ccffcc"} map={texture}/>
}

export const TexturizedMesh = ({image}: { image:string }) => {
    const fallbackimg = 'https://apotek1-dna-images.b-cdn.net/images%2FScreenshot%202024-02-07%20at%2001.42.24.png?alt=media&width=500&crop_gravity=center&aspect_ratio=1000:999'
    try {
        const texture = useLoader(TextureLoader, image || fallbackimg);
        return <Suspense fallback={<FallbackMesh />}><meshStandardMaterial key={"mesh-" + image} side={DoubleSide} color={"#ccffcc"} map={texture}/></Suspense>
    } catch {
        const texture = useLoader(TextureLoader, fallbackimg);
        return <Suspense fallback={<FallbackMesh />}><meshStandardMaterial key={"mesh-" + image} side={DoubleSide} color={"#ccffcc"} map={texture}/></Suspense>
    }
}

const _images = Array.apply(null, Array(20))
    .map(() => "https://apotek1-dna-images.b-cdn.net/images%2FScreenshot%202024-02-07%20at%2001.42.24.png?alt=media&width=500&crop_gravity=center&aspect_ratio=1000:999");
export function Model({mainImage, className, images=_images, animate = true, useDefaultCamera=true, freeForAll=false, ...props}: {
    mainImage: string,
    className: string,
    animate: boolean,
    useDefaultCamera: boolean,
    images: string[],
    freeForAll: boolean
}) {
    const group = useRef();
    const {nodes, materials, animations} = useGLTF("/dna_for_the_app_only_camera_visible_v1.glb");
    const {actions} = useAnimations(animations, group);
    const img = 'https://apotek1-dna-images.b-cdn.net/images%2FScreenshot%202024-02-07%20at%2001.42.24.png?alt=media&width=500&crop_gravity=center&aspect_ratio=1000:999'
    const texture = TextureLoadedImage(img);
    //const mainImageTexture = useLoader(TextureLoader, mainImage);
    const mainImageTexture = TextureLoadedImage(mainImage);
    const textureLoader = new TextureLoader();


    const [imageTextures, setImageTextures] = useState(images
        .map((element) => {
            return <TexturizedMesh image={element} />
        }))

    const dnaRef = useRef()

    useEffect(() => {

        if (animate) {

            setTimeout(() => {
                actions.EmptyAction.setLoop(LoopOnce, 0)
                actions.EmptyAction.play()

            }, 100)
        }

    }, []);

/*    useEffect(() => {
        if (dnaRef.current && dnaRef.current.geometry) {
            dnaRef.current.geometry.center()
        }
    }, [dnaRef]);

    console.log({helper})*/

    return (
        <group encoding={SRGBColorSpace} ref={group} {...props} dispose={null}>

            <group name="Scene">
                <group
                    name="cam_control"
                    position={[123.964, 13.46, 0.637]}
                    rotation={[1.812, -0.111, -1.995]}
                    scale={2.118}
                >
                    <PerspectiveCamera
                        name="Camera001"
                        makeDefault={useDefaultCamera}

                        far={1000}
                        near={0.1}
                        fov={39.598}
                        position={[0, 4.956, 0]}
                        rotation={[-Math.PI / 2, 0, 0]}
                    />
                </group>
                <mesh
                    name="main_picture"
                    geometry={nodes.main_picture.geometry}
                    //position={[126.789, -0.958, 0]}
                    //position={[0, 0, 0]}
                    position={freeForAll ? [3.5, -17, 0] : [126.789, -0.958, 0]}

                >
                    <meshStandardMaterial side={DoubleSide} map={mainImageTexture}/>
                </mesh>
                <group ref={dnaRef} encoding={SRGBColorSpace} name="dna" scale={1}
                    //position={[126.789, -0.958, 0]}
                    //position={[3.5, -17, 0]}
                       position={freeForAll ? [3.5, -17, 0] : [126.789, -0.958, 0]}
                >
                    <mesh
                        name="Circle002"

                        geometry={nodes.Circle002.geometry}
                        material={materials.digital_back}
                    />
                    <mesh
                        name="Circle002_1"
                        encoding={SRGBColorSpace}
                        geometry={nodes.Circle002_1.geometry}
                        material={materials.green_neon}
                    >
                        {/*<meshStandardMaterial side={DoubleSide} map={materials.green_neon}/>*/}
                    </mesh>
                    <mesh
                        name="Circle002_2"
                        encoding={SRGBColorSpace}
                        geometry={nodes.Circle002_2.geometry}
                        material={materials.blue_neon}
                    />
                    <mesh
                        name="Circle002_3"
                        encoding={SRGBColorSpace}
                        geometry={nodes.Circle002_3.geometry}
                        material={materials.circle_blue}
                    />
                    <mesh
                        name="Circle002_4"
                        encoding={SRGBColorSpace}
                        geometry={nodes.Circle002_4.geometry}
                        material={materials.circle_green}
                    />
                    <mesh
                        name="Circle002_5"


                        geometry={nodes.Circle002_5.geometry}
                        material={materials["circle_green_jeg-bryr-meg"]}
                    />
                    <mesh
                        name="Circle002_6"


                        geometry={nodes.Circle002_6.geometry}
                        material={materials["circle_green_jeg-er-engasjert"]}
                    />
                    <mesh
                        name="Circle002_7"


                        geometry={nodes.Circle002_7.geometry}
                        material={materials["circle_green_jeg-er-pålitelig"]}
                    />
                    <mesh
                        name="Circle002_8"


                        geometry={nodes.Circle002_8.geometry}
                        material={materials["circle_blue_jeg-bryr-meg"]}
                    />
                    <mesh
                        name="Circle002_9"


                        geometry={nodes.Circle002_9.geometry}
                        material={materials["circle_blue_jeg-er-engasjert"]}
                    />
                    <mesh
                        name="Circle002_10"


                        geometry={nodes.Circle002_10.geometry}
                        material={materials["circle_blue_jeg-er-pålitelig"]}
                    />
                    <mesh
                        name="Circle002_11"


                        geometry={nodes.Circle002_11.geometry}
                    >
                        {imageTextures[0]}
                    </mesh>
                    <mesh
                        name="Circle002_12"


                        geometry={nodes.Circle002_12.geometry}
                    >
                        {imageTextures[1]}
                    </mesh>
                    <mesh
                        name="Circle002_13"


                        geometry={nodes.Circle002_13.geometry}
                    >
                        {imageTextures[2]}

                    </mesh>
                    <mesh
                        name="Circle002_14"


                        geometry={nodes.Circle002_14.geometry}
                    >
                        {imageTextures[3]}
                    </mesh>
                    <mesh
                        name="Circle002_15"


                        geometry={nodes.Circle002_15.geometry}
                    >
                        {imageTextures[4]}
                    </mesh>
                    <mesh
                        name="Circle002_16"


                        geometry={nodes.Circle002_16.geometry}
                    >
                        {imageTextures[6]}
                    </mesh>
                    <mesh
                        name="Circle002_17"


                        geometry={nodes.Circle002_17.geometry}
                    >
                        {imageTextures[7]}
                    </mesh>
                    <mesh
                        name="Circle002_18"


                        geometry={nodes.Circle002_18.geometry}
                    >
                        {imageTextures[8]}
                    </mesh>
                    <mesh
                        name="Circle002_19"


                        geometry={nodes.Circle002_19.geometry}
                    >
                        {imageTextures[9]}
                    </mesh>
                    <mesh
                        name="Circle002_20"


                        geometry={nodes.Circle002_20.geometry}
                    >
                        {imageTextures[10]}
                    </mesh>
                    <mesh
                        name="Circle002_21"


                        geometry={nodes.Circle002_21.geometry}
                    >
                        {imageTextures[12]}
                    </mesh>
                    <mesh
                        name="Circle002_22"


                        geometry={nodes.Circle002_22.geometry}
                    >
                        {imageTextures[13]}
                    </mesh>
                    <mesh
                        name="Circle002_23"


                        geometry={nodes.Circle002_23.geometry}
                    >
                        {imageTextures[14]}
                    </mesh>
                    <mesh
                        name="Circle002_24"


                        geometry={nodes.Circle002_24.geometry}
                    >
                        {imageTextures[15]}
                    </mesh>
                    <mesh
                        name="Circle002_25"


                        geometry={nodes.Circle002_25.geometry}
                    >
                        {imageTextures[16]}
                    </mesh>
                    <mesh
                        name="Circle002_26"


                        geometry={nodes.Circle002_26.geometry}
                    >
                        {imageTextures[17]}
                    </mesh>
                    <mesh
                        name="Circle002_27"


                        geometry={nodes.Circle002_27.geometry}
                    >
                        {imageTextures[18]}
                    </mesh>
                    <mesh
                        name="Circle002_28"


                        geometry={nodes.Circle002_28.geometry}
                    >
                        {imageTextures[19]}
                    </mesh>
                    <mesh
                        name="Circle002_29"


                        geometry={nodes.Circle002_29.geometry}
                    >
                        {imageTextures[20]}
                    </mesh>
                    <mesh
                        name="Circle002_30"


                        geometry={nodes.Circle002_30.geometry}
                    >
                        {imageTextures[21]}
                    </mesh>
                    <mesh
                        name="Circle002_34"


                        geometry={nodes.Circle002_34.geometry}
                    >
                        {imageTextures[22]}
                    </mesh>
                    <mesh
                        name="Circle002_35"


                        geometry={nodes.Circle002_35.geometry}
                    >
                        {imageTextures[23]}
                    </mesh>
                    <mesh
                        name="Circle002_36"


                        geometry={nodes.Circle002_36.geometry}
                    >
                        {imageTextures[24]}
                    </mesh>
                    <mesh
                        name="Circle002_37"


                        geometry={nodes.Circle002_37.geometry}
                    >
                        {imageTextures[25]}
                    </mesh>
                    <mesh
                        name="Circle002_38"


                        geometry={nodes.Circle002_38.geometry}
                    >
                        {imageTextures[26]}
                    </mesh>
                    <mesh
                        name="Circle002_39"


                        geometry={nodes.Circle002_39.geometry}

                    >
                        {imageTextures[27]}
                    </mesh>
                    <mesh
                        name="Circle002_40"


                        geometry={nodes.Circle002_40.geometry}

                    >
                        {imageTextures[28]}
                    </mesh>
                    <mesh
                        name="Circle002_41"


                        geometry={nodes.Circle002_41.geometry}

                    >
                        {imageTextures[29]}
                    </mesh>
                    <mesh
                        name="Circle002_42"


                        geometry={nodes.Circle002_42.geometry}

                    >
                        {imageTextures[30]}
                    </mesh>
                    <mesh
                        name="Circle002_43"


                        geometry={nodes.Circle002_43.geometry}

                    >
                        {imageTextures[31]}
                    </mesh>
                    <mesh
                        name="Circle002_44"


                        geometry={nodes.Circle002_44.geometry}

                    >
                        {imageTextures[32]}
                    </mesh>
                    <mesh
                        name="Circle002_45"


                        geometry={nodes.Circle002_45.geometry}

                    >
                        {imageTextures[33]}
                    </mesh>
                    <mesh
                        name="Circle002_46"


                        geometry={nodes.Circle002_46.geometry}

                    >
                        {imageTextures[34]}
                    </mesh>
                    <mesh
                        name="Circle002_47"


                        geometry={nodes.Circle002_47.geometry}

                    >
                        {imageTextures[35]}
                    </mesh>
                    <mesh
                        name="Circle002_48"


                        geometry={nodes.Circle002_48.geometry}

                    >
                        {imageTextures[36]}
                    </mesh>
                    <mesh
                        name="Circle002_49"


                        geometry={nodes.Circle002_49.geometry}

                    >
                        {imageTextures[37]}
                    </mesh>
                    <mesh
                        name="Circle002_50"


                        geometry={nodes.Circle002_50.geometry}

                    >
                        {imageTextures[38]}
                    </mesh>
                    <mesh
                        name="Circle002_51"


                        geometry={nodes.Circle002_51.geometry}

                    >
                        {imageTextures[39]}
                    </mesh>
                    <mesh
                        name="Circle002_52"


                        geometry={nodes.Circle002_52.geometry}

                    >
                        {imageTextures[40]}
                    </mesh>
                    <mesh
                        name="Circle002_53"


                        geometry={nodes.Circle002_53.geometry}

                    >
                        {imageTextures[41]}
                    </mesh>
                    <mesh
                        name="Circle002_54"


                        geometry={nodes.Circle002_54.geometry}

                    >
                        {imageTextures[42]}
                    </mesh>
                    <mesh
                        name="Circle002_55"


                        geometry={nodes.Circle002_55.geometry}

                    >
                        {imageTextures[43]}
                    </mesh>
                    <mesh
                        name="Circle002_56"


                        geometry={nodes.Circle002_56.geometry}

                    >
                        {imageTextures[44]}
                    </mesh>
                    <mesh
                        name="Circle002_57"


                        geometry={nodes.Circle002_57.geometry}

                    >
                        {imageTextures[45]}
                    </mesh>
                    <mesh
                        name="Circle002_58"


                        geometry={nodes.Circle002_58.geometry}

                    >
                        {imageTextures[46]}
                    </mesh>
                    <mesh
                        name="Circle002_59"


                        geometry={nodes.Circle002_59.geometry}

                    >
                        {imageTextures[47]}
                    </mesh>
                    <mesh
                        name="Circle002_60"


                        geometry={nodes.Circle002_60.geometry}

                    >
                        {imageTextures[48]}
                    </mesh>
                    <mesh
                        name="Circle002_61"


                        geometry={nodes.Circle002_61.geometry}

                    >
                        {imageTextures[49]}
                    </mesh>
                    <mesh
                        name="Circle002_62"


                        geometry={nodes.Circle002_62.geometry}

                    >
                        {imageTextures[50]}
                    </mesh>
                    <mesh
                        name="Circle002_63"


                        geometry={nodes.Circle002_63.geometry}

                    >
                        {imageTextures[51]}
                    </mesh>
                    <mesh
                        name="Circle002_64"


                        geometry={nodes.Circle002_64.geometry}

                    >
                        {imageTextures[52]}
                    </mesh>
                    <mesh
                        name="Circle002_65"


                        geometry={nodes.Circle002_65.geometry}

                    >
                        {imageTextures[53]}
                    </mesh>
                    <mesh
                        name="Circle002_66"


                        geometry={nodes.Circle002_66.geometry}

                    >
                        {imageTextures[54]}
                    </mesh>
                    <mesh
                        name="Circle002_67"


                        geometry={nodes.Circle002_67.geometry}

                    >
                        {imageTextures[55]}
                    </mesh>
                    <mesh
                        name="Circle002_68"


                        geometry={nodes.Circle002_68.geometry}

                    >
                        {imageTextures[56]}
                    </mesh>
                    <mesh
                        name="Circle002_69"


                        geometry={nodes.Circle002_69.geometry}

                    >
                        {imageTextures[57]}
                    </mesh>
                    <mesh
                        name="Circle002_70"


                        geometry={nodes.Circle002_70.geometry}

                    >
                        {imageTextures[58]}
                    </mesh>
                    <mesh
                        name="Circle002_71"


                        geometry={nodes.Circle002_71.geometry}

                    >
                        {imageTextures[59]}
                    </mesh>
                    <mesh
                        name="Circle002_72"


                        geometry={nodes.Circle002_72.geometry}

                    >
                        {imageTextures[60]}
                    </mesh>
                    <mesh
                        name="Circle002_73"


                        geometry={nodes.Circle002_73.geometry}

                    >
                        {imageTextures[61]}
                    </mesh>
                    <mesh
                        name="Circle002_74"


                        geometry={nodes.Circle002_74.geometry}

                    >
                        {imageTextures[62]}
                    </mesh>
                    <mesh
                        name="Circle002_75"


                        geometry={nodes.Circle002_75.geometry}

                    >
                        {imageTextures[63]}
                    </mesh>
                    <mesh
                        name="Circle002_76"


                        geometry={nodes.Circle002_76.geometry}

                    >
                        {imageTextures[64]}
                    </mesh>
                    <mesh
                        name="Circle002_77"


                        geometry={nodes.Circle002_77.geometry}

                    >
                        {imageTextures[65]}
                    </mesh>
                    <mesh
                        name="Circle002_78"


                        geometry={nodes.Circle002_78.geometry}

                    >
                        {imageTextures[66]}
                    </mesh>
                    <mesh
                        name="Circle002_79"


                        geometry={nodes.Circle002_79.geometry}

                    >
                        {imageTextures[67]}
                    </mesh>
                    <mesh
                        name="Circle002_80"


                        geometry={nodes.Circle002_80.geometry}

                    >
                        {imageTextures[68]}
                    </mesh>
                    <mesh
                        name="Circle002_81"


                        geometry={nodes.Circle002_81.geometry}

                    >
                        {imageTextures[69]}
                    </mesh>
                    <mesh
                        name="Circle002_82"


                        geometry={nodes.Circle002_82.geometry}

                    >
                        {imageTextures[70]}
                    </mesh>
                    <mesh
                        name="Circle002_83"


                        geometry={nodes.Circle002_83.geometry}

                    >
                        {imageTextures[71]}
                    </mesh>
                    <mesh
                        name="Circle002_84"


                        geometry={nodes.Circle002_84.geometry}

                    >
                        {imageTextures[72]}
                    </mesh>
                    <mesh
                        name="Circle002_85"


                        geometry={nodes.Circle002_85.geometry}

                    >
                        {imageTextures[73]}
                    </mesh>
                    <mesh
                        name="Circle002_86"


                        geometry={nodes.Circle002_86.geometry}

                    >
                        {imageTextures[74]}
                    </mesh>
                    <mesh
                        name="Circle002_87"


                        geometry={nodes.Circle002_87.geometry}

                    >
                        {imageTextures[75]}
                    </mesh>
                    <mesh
                        name="Circle002_88"


                        geometry={nodes.Circle002_88.geometry}

                    >
                        {imageTextures[76]}
                    </mesh>
                    <mesh
                        name="Circle002_89"


                        geometry={nodes.Circle002_89.geometry}

                    >
                        {imageTextures[77]}
                    </mesh>
                    <mesh
                        name="Circle002_90"


                        geometry={nodes.Circle002_90.geometry}

                    >
                        {imageTextures[78]}
                    </mesh>
                    <mesh
                        name="Circle002_91"


                        geometry={nodes.Circle002_91.geometry}

                    >
                        {imageTextures[79]}
                    </mesh>
                    <mesh
                        name="Circle002_92"


                        geometry={nodes.Circle002_92.geometry}

                    >
                        {imageTextures[80]}
                    </mesh>
                    <mesh
                        name="Circle002_93"


                        geometry={nodes.Circle002_93.geometry}

                    >
                        {imageTextures[81]}
                    </mesh>
                    <mesh
                        name="Circle002_94"


                        geometry={nodes.Circle002_94.geometry}

                    >
                        {imageTextures[82]}
                    </mesh>
                    <mesh
                        name="Circle002_95"


                        geometry={nodes.Circle002_95.geometry}

                    >
                        {imageTextures[83]}
                    </mesh>
                    <mesh
                        name="Circle002_96"


                        geometry={nodes.Circle002_96.geometry}

                    >
                        {imageTextures[84]}
                    </mesh>
                    <mesh
                        name="Circle002_97"


                        geometry={nodes.Circle002_97.geometry}

                    >
                        {imageTextures[85]}
                    </mesh>
                    <mesh
                        name="Circle002_98"


                        geometry={nodes.Circle002_98.geometry}

                    >
                        {imageTextures[86]}
                    </mesh>
                    <mesh
                        name="Circle002_99"


                        geometry={nodes.Circle002_99.geometry}

                    >
                        {imageTextures[87]}
                    </mesh>
                    <mesh
                        name="Circle002_100"


                        geometry={nodes.Circle002_100.geometry}

                    >
                        {imageTextures[88]}
                    </mesh>
                    <mesh
                        name="Circle002_101"


                        geometry={nodes.Circle002_101.geometry}

                    >
                        {imageTextures[89]}
                    </mesh>
                    <mesh
                        name="Circle002_102"


                        geometry={nodes.Circle002_102.geometry}

                    >
                        {imageTextures[90]}
                    </mesh>
                    <mesh
                        name="Circle002_103"


                        geometry={nodes.Circle002_103.geometry}

                    >
                        {imageTextures[91]}
                    </mesh>
                    <mesh
                        name="Circle002_104"


                        geometry={nodes.Circle002_104.geometry}

                    >
                        {imageTextures[92]}
                    </mesh>
                    <mesh
                        name="Circle002_105"


                        geometry={nodes.Circle002_105.geometry}

                    >
                        {imageTextures[93]}
                    </mesh>

                </group>
            </group>
        </group>
    );
}

useGLTF.preload("/dna_for_the_app_only_camera_visible_v1.glb");

export default Model
