import * as React from 'react';
import {Suspense, useEffect, useRef, useState} from 'react';
import {Canvas, useFrame, useLoader} from '@react-three/fiber'
import {Html, useAnimations, useGLTF, useProgress} from "@react-three/drei";
import {Color, DoubleSide, Texture, TextureLoader} from "three";
// import img from "../../public/img.png?url"
import mask from "../../public/screen_mask_v1.png?url"
import film from "../../public/bg_loop_v2.mp4?url"
import { getDatabase, set, ref, onValue, onChildAdded } from "firebase/database";

import { collection, query, where, getDocs } from "firebase/firestore";
import {useFirestore} from "reactfire";


const getImages = async (db) => {
    const q = query(collection(db, "images"),
        //where("capital", "==", true)
    );

    const images = []
    const querySnapshot = await getDocs(q);

    await querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        //return console.log(doc.id, " => ", doc.data());
        images.push({id: doc.id, data: doc.data()})
    });
    return images
}

export const transformUrlToBunny = (firestoreUrl: string, width: string="500"): string => {
    // https://firebasestorage.googleapis.com/v0/b/apotek1-dna.appspot.com/o/images%2F64b98ce4-401c-4c39-b00d-2f4677d8bd95.jpg?alt=media&token=29a03d33-6d1e-488e-bff9-ad55c8e1e041
    // 'https://apotek1-dna-images.b-cdn.net/images%2FScreenshot%202024-02-07%20at%2001.42.24.png?alt=media&width=500&crop_gravity=center&aspect_ratio=1000:999
    return firestoreUrl.replace(
        'https://firebasestorage.googleapis.com/v0/b/apotek1-dna.appspot.com/o/',
        'https://apotek1-dna-images.b-cdn.net/'
    ).toString() + '&width=' + width + '&crop_gravity=center&aspect_ratio=1000:999'
}


export function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

const FadeMesh = ({map}) => {
    // const textureLoader = new TextureLoader();

    return <meshStandardMaterial  color={"#ccffcc"} map={map}/>
}

export function Model(props) {
    const rtDb = getDatabase();
    const imgDBRef = ref(rtDb, 'images')
    const textureLoader = new TextureLoader();

    const allImages = []

    const [images, setImages] = useState(Array.apply(null, Array(99))
        .map(function (y, i) {
            return {
                key: i,
                imageUrl: 'https://apotek1-dna-images.b-cdn.net/images%2FScreenshot%202024-02-07%20at%2001.42.24.png?alt=media&width=500&crop_gravity=center&aspect_ratio=1000:999',
                texture: textureLoader.load('https://apotek1-dna-images.b-cdn.net/images%2FScreenshot%202024-02-07%20at%2001.42.24.png?alt=media&width=500&crop_gravity=center&aspect_ratio=1000:999')
            }}))

    useEffect(() => {
        setInterval(() => {
            if (allImages.length > 10) {
                const randomIndex = getRandomInt(images.length)
                const randomImage = allImages[getRandomInt(allImages.length)]
                setImages((_images) => {
                    return [..._images.slice(0,randomIndex), {key: "rimage"+randomImage+randomIndex, imageUrl: randomImage, texture:  textureLoader.load(transformUrlToBunny(randomImage, "500"))}, ..._images.slice(randomIndex+1)]
                })
            }
        }, 1000)
    }, []);

    useEffect(() => {
        onChildAdded(imgDBRef, (snapshot) => {
            const randomIndex = getRandomInt(images.length)
            const data = snapshot.val();

            if (data.timestamp > 1707980910000) {
                setImages((_images) => {
                    return [..._images.slice(0,randomIndex), {key: data.imageId, imageUrl: data.imageUrl, texture:  textureLoader.load(transformUrlToBunny(data.imageUrl, "500"))}, ..._images.slice(randomIndex+1)]
                })
                allImages.push(data.imageUrl)
            }

        });

    }, []);

    const db = useFirestore()
    const group = useRef();
    const {nodes, materials, animations} = useGLTF("/screen_circles_v3.glb");
    const {actions} = useAnimations(animations, group);

    const img = 'https://apotek1-dna-images.b-cdn.net/images%2FScreenshot%202024-02-07%20at%2001.42.24.png?alt=media&width=500&crop_gravity=center&aspect_ratio=1000:999'

    const texture = useLoader(TextureLoader, img);

    useEffect(() => {

    }, [images]);


    useEffect(() => {
        //actions.play()
        //actions?.jump.play()

        Object.keys(actions).forEach((action) => {
            actions[action].play()
        })
    }, []);

    return (
        <group ref={group} {...props} dispose={null}>
            <group name="Scene">
                <group name="Empty" position={[0, -0.03, 0]} scale={0.897}>
                    <mesh
                        name="circle_a001"
                        geometry={nodes.circle_a001.geometry}
                        position={[0.206, -0.102, -3.863]}

                    >
                        <FadeMesh color={"#ccffcc"} map={images[97].texture}/>
                    </mesh>

                    <mesh
                        name="circle_b001"
                        geometry={nodes.circle_b001.geometry}
                        map={texture}
                        position={[1.237, -0.102, -3.768]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[0].texture}/>
                    </mesh>
                    <mesh
                        name="circle_b002"
                        geometry={nodes.circle_b002.geometry}
                        map={texture}
                        position={[-0.591, -0.102, -4.005]}
                        scale={[0.987, 1, 0.987]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[1].texture}/>
                    </mesh>
                    <mesh
                        name="circle_c001"


                        geometry={nodes.circle_c001.geometry}
                        map={texture}
                        position={[-1.129, -0.102, -3.677]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[2].texture}/>
                    </mesh>
                    <mesh
                        name="circle_d001"


                        geometry={nodes.circle_d001.geometry}
                        map={texture}
                        position={[-1.123, -0.102, -4.173]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[3].texture}/>
                    </mesh>
                    <mesh
                        name="circle_d002"


                        geometry={nodes.circle_d002.geometry}
                        map={texture}
                        position={[-1.542, -0.102, -3.86]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[4].texture}/>
                    </mesh>
                    <mesh
                        name="circle_d003"


                        geometry={nodes.circle_d003.geometry}
                        map={texture}
                        position={[0.796, -0.102, -4.085]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[5].texture}/>
                    </mesh>
                    <mesh
                        name="circle_e001"


                        geometry={nodes.circle_e001.geometry}
                        map={texture}
                        position={[-0.308, -0.102, -3.548]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[6].texture}/>
                    </mesh>
                    <mesh
                        name="circle_e002"


                        geometry={nodes.circle_e002.geometry}
                        map={texture}
                        position={[0.757, -0.102, -3.652]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[7].texture}/>
                    </mesh>
                    <mesh
                        name="circle_e003"


                        geometry={nodes.circle_e003.geometry}
                        map={texture}
                        position={[1.075, -0.102, -4.234]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[8].texture}/>
                    </mesh>
                    <mesh
                        name="circle_a002"


                        geometry={nodes.circle_a002.geometry}
                        map={texture}
                        position={[-0.375, -0.102, -2.907]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[9].texture}/>
                    </mesh>
                    <mesh
                        name="circle_b003"


                        geometry={nodes.circle_b003.geometry}
                        map={texture}
                        position={[-1.238, -0.102, -2.799]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[10].texture}/>
                    </mesh>
                    <mesh
                        name="circle_b004"


                        geometry={nodes.circle_b004.geometry}
                        map={texture}
                        position={[0.885, -0.102, -2.798]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[96].texture}/>
                    </mesh>
                    <mesh
                        name="circle_c002"


                        geometry={nodes.circle_c002.geometry}
                        map={texture}
                        position={[0.325, -0.102, -3.104]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[10].texture}/>
                    </mesh>
                    <mesh
                        name="circle_d004"


                        geometry={nodes.circle_d004.geometry}
                        map={texture}
                        position={[0.187, -0.102, -2.613]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[11].texture}/>
                    </mesh>
                    <mesh
                        name="circle_d005"


                        geometry={nodes.circle_d005.geometry}
                        map={texture}
                        position={[1.244, -0.102, -3.213]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[12].texture}/>
                    </mesh>
                    <mesh
                        name="circle_d006"


                        geometry={nodes.circle_d006.geometry}
                        map={texture}
                        position={[1.409, -0.102, -2.665]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[13].texture}/>
                    </mesh>
                    <mesh
                        name="circle_e004"


                        geometry={nodes.circle_e004.geometry}
                        map={texture}
                        position={[-0.905, -0.102, -3.158]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[14].texture}/>
                    </mesh>
                    <mesh
                        name="circle_e005"


                        geometry={nodes.circle_e005.geometry}
                        map={texture}
                        position={[0.7, -0.102, -3.269]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[15].texture}/>
                    </mesh>
                    <mesh
                        name="circle_e030"


                        geometry={nodes.circle_e030.geometry}
                        map={texture}
                        position={[1.588, -0.102, -2.93]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[16].texture}/>
                    </mesh>
                    <mesh
                        name="circle_a003"


                        geometry={nodes.circle_a003.geometry}
                        map={texture}
                        position={[0.355, -0.102, -1.926]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[17].texture}/>
                    </mesh>
                    <mesh
                        name="circle_b005"


                        geometry={nodes.circle_b005.geometry}
                        map={texture}
                        position={[-0.525, -0.102, -1.828]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[18].texture}/>
                    </mesh>
                    <mesh
                        name="circle_b006"


                        geometry={nodes.circle_b006.geometry}
                        map={texture}
                        position={[1.154, -0.102, -2.068]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[19].texture}/>
                    </mesh>
                    <mesh
                        name="circle_c003"


                        geometry={nodes.circle_c003.geometry}
                        map={texture}
                        position={[-1.106, -0.102, -2.072]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[20].texture}/>
                    </mesh>
                    <mesh
                        name="circle_d007"


                        geometry={nodes.circle_d007.geometry}
                        map={texture}
                        position={[-0.184, -0.102, -2.249]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[21].texture}/>
                    </mesh>
                    <mesh
                        name="circle_d008"


                        geometry={nodes.circle_d008.geometry}
                        map={texture}
                        position={[-1.524, -0.102, -1.885]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[22].texture}/>
                    </mesh>
                    <mesh
                        name="circle_d009"


                        geometry={nodes.circle_d009.geometry}
                        map={texture}
                        position={[-1.242, -0.102, -1.644]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[23].texture}/>
                    </mesh>
                    <mesh
                        name="circle_e006"


                        geometry={nodes.circle_e006.geometry}
                        map={texture}
                        position={[-1.49, -0.102, -2.197]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[24].texture}/>
                    </mesh>
                    <mesh
                        name="circle_e007"


                        geometry={nodes.circle_e007.geometry}
                        map={texture}
                        position={[-0.76, -0.102, -2.283]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[25].texture}/>
                    </mesh>
                    <mesh
                        name="circle_e008"


                        geometry={nodes.circle_e008.geometry}
                        map={texture}
                        position={[0.877, -0.102, -1.653]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[26].texture}/>
                    </mesh>
                    <mesh
                        name="circle_e009"


                        geometry={nodes.circle_e009.geometry}
                        map={texture}
                        position={[1.31, -0.102, -1.602]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[27].texture}/>
                    </mesh>
                    <mesh
                        name="circle_e010"


                        geometry={nodes.circle_e010.geometry}
                        map={texture}
                        position={[1.533, -0.102, -1.753]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[28].texture}/>
                    </mesh>
                    <mesh
                        name="circle_a00"


                        geometry={nodes.circle_a00.geometry}
                        map={texture}
                        position={[-0.212, -0.102, -1.008]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[29].texture}/>
                    </mesh>
                    <mesh
                        name="circle_b007"


                        geometry={nodes.circle_b007.geometry}
                        map={texture}
                        position={[-1.016, -0.102, -1.101]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[30].texture}/>
                    </mesh>
                    <mesh
                        name="circle_b008"


                        geometry={nodes.circle_b008.geometry}
                        map={texture}
                        position={[1.252, -0.102, -1.095]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[31].texture}/>
                    </mesh>
                    <mesh
                        name="circle_c004"


                        geometry={nodes.circle_c004.geometry}
                        map={texture}
                        position={[0.466, -0.102, -0.76]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[32].texture}/>
                    </mesh>
                    <mesh
                        name="circle_d010"


                        geometry={nodes.circle_d010.geometry}
                        map={texture}
                        position={[-1.383, -0.102, -0.703]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[33].texture}/>
                    </mesh>
                    <mesh
                        name="circle_d011"


                        geometry={nodes.circle_d011.geometry}
                        map={texture}
                        position={[0.367, -0.102, -1.263]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[34].texture}/>
                    </mesh>
                    <mesh
                        name="circle_d012"


                        geometry={nodes.circle_d012.geometry}
                        map={texture}
                        position={[0.71, -0.102, -1.141]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[35].texture}/>
                    </mesh>
                    <mesh
                        name="circle_d013"


                        geometry={nodes.circle_d013.geometry}
                        map={texture}
                        position={[0.909, -0.102, -0.674]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[36].texture}/>
                    </mesh>
                    <mesh
                        name="circle_e011"


                        geometry={nodes.circle_e011.geometry}
                        map={texture}
                        position={[-1.501, -0.102, -0.995]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[37].texture}/>
                    </mesh>
                    <mesh
                        name="circle_e012"


                        geometry={nodes.circle_e012.geometry}
                        map={texture}
                        position={[-0.714, -0.102, -0.708]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[38].texture}/>
                    </mesh>
                    <mesh
                        name="circle_a005"


                        geometry={nodes.circle_a005.geometry}
                        map={texture}
                        position={[0.48, -0.102, 0.03]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[39].texture}/>
                    </mesh>
                    <mesh
                        name="circle_b010"


                        geometry={nodes.circle_b010.geometry}
                        map={texture}
                        position={[-0.388, -0.102, 0.124]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[40].texture}/>
                    </mesh>
                    <mesh
                        name="circle_c005"


                        geometry={nodes.circle_c005.geometry}
                        map={texture}
                        position={[-0.933, -0.102, -0.212]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[41].texture}/>
                    </mesh>
                    <mesh
                        name="circle_c006"


                        geometry={nodes.circle_c006.geometry}
                        map={texture}
                        position={[-1.363, -0.102, 0.177]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[42].texture}/>
                    </mesh>
                    <mesh
                        name="circle_d014"


                        geometry={nodes.circle_d014.geometry}
                        map={texture}
                        position={[-0.054, -0.102, -0.305]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[43].texture}/>
                    </mesh>
                    <mesh
                        name="circle_d015"


                        geometry={nodes.circle_d015.geometry}
                        map={texture}
                        position={[-1.393, -0.102, -0.275]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[44].texture}/>
                    </mesh>
                    <mesh
                        name="circle_d016"


                        geometry={nodes.circle_d016.geometry}
                        map={texture}
                        position={[-0.916, -0.102, 0.296]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[45].texture}/>
                    </mesh>
                    <mesh
                        name="circle_e013"


                        geometry={nodes.circle_e013.geometry}
                        map={texture}
                        position={[-0.539, -0.102, -0.354]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[46].texture}/>
                    </mesh>
                    <mesh
                        name="circle_e014"


                        geometry={nodes.circle_e014.geometry}
                        map={texture}
                        position={[1.033, -0.102, 0.326]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[47].texture}/>
                    </mesh>
                    <mesh
                        name="circle_a006"


                        geometry={nodes.circle_a006.geometry}
                        map={texture}
                        position={[-0.802, -0.102, 1.008]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[48].texture}/>
                    </mesh>
                    <mesh
                        name="circle_b011"


                        geometry={nodes.circle_b011.geometry}
                        map={texture}
                        position={[0.007, -0.102, 1.096]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[49].texture}/>
                    </mesh>
                    <mesh
                        name="circle_b012"


                        geometry={nodes.circle_b012.geometry}
                        map={texture}
                        position={[1.163, -0.102, 0.848]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[50].texture}/>
                    </mesh>
                    <mesh
                        name="circle_c007"


                        geometry={nodes.circle_c007.geometry}
                        map={texture}
                        position={[0.629, -0.102, 1.185]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[51].texture}/>
                    </mesh>
                    <mesh
                        name="circle_d017"


                        geometry={nodes.circle_d017.geometry}
                        map={texture}
                        position={[-1.39, -0.102, 1.238]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[52].texture}/>
                    </mesh>
                    <mesh
                        name="circle_d018"


                        geometry={nodes.circle_d018.geometry}
                        map={texture}
                        position={[-1.538, -0.102, 0.909]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[53].texture}/>
                    </mesh>
                    <mesh
                        name="circle_d019"


                        geometry={nodes.circle_d019.geometry}
                        map={texture}
                        position={[0.633, -0.102, 0.733]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[54].texture}/>
                    </mesh>
                    <mesh
                        name="circle_e015"


                        geometry={nodes.circle_e015.geometry}
                        map={texture}
                        position={[-1.293, -0.102, 0.699]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[55].texture}/>
                    </mesh>
                    <mesh
                        name="circle_e016"


                        geometry={nodes.circle_e016.geometry}
                        map={texture}
                        position={[-0.303, -0.102, 0.701]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[56].texture}/>
                    </mesh>
                    <mesh
                        name="circle_e017"


                        geometry={nodes.circle_e017.geometry}
                        map={texture}
                        position={[0.317, -0.102, 0.709]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[57].texture}/>
                    </mesh>
                    <mesh
                        name="circle_e018"


                        geometry={nodes.circle_e018.geometry}
                        map={texture}
                        position={[1.014, -0.102, 1.32]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[58].texture}/>
                    </mesh>
                    <mesh
                        name="circle_e019"


                        geometry={nodes.circle_e019.geometry}
                        map={texture}
                        position={[1.499, -0.102, 1.214]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[59].texture}/>
                    </mesh>
                    <mesh
                        name="circle_a007"


                        geometry={nodes.circle_a007.geometry}
                        map={texture}
                        position={[0.185, -0.102, 1.98]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[60].texture}/>
                    </mesh>
                    <mesh
                        name="circle_b013"


                        geometry={nodes.circle_b013.geometry}
                        map={texture}
                        position={[1.043, -0.102, 2.069]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[61].texture}/>
                    </mesh>
                    <mesh
                        name="circle_b014"


                        geometry={nodes.circle_b014.geometry}
                        map={texture}
                        position={[-0.676, -0.102, 2.065]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[62].texture}/>
                    </mesh>
                    <mesh
                        name="circle_c008"


                        geometry={nodes.circle_c008.geometry}
                        map={texture}
                        position={[-1.21, -0.102, 1.728]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[63].texture}/>
                    </mesh>
                    <mesh
                        name="circle_d020"


                        geometry={nodes.circle_d020.geometry}
                        map={texture}
                        position={[-1.2, -0.102, 2.192]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[64].texture}/>
                    </mesh>
                    <mesh
                        name="circle_d021"


                        geometry={nodes.circle_d021.geometry}
                        map={texture}
                        position={[-1.531, -0.102, 2.044]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[65].texture}/>
                    </mesh>
                    <mesh
                        name="circle_d022"


                        geometry={nodes.circle_d022.geometry}
                        map={texture}
                        position={[-0.348, -0.102, 1.639]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[66].texture}/>
                    </mesh>
                    <mesh
                        name="circle_d023"


                        geometry={nodes.circle_d023.geometry}
                        map={texture}
                        position={[0.717, -0.102, 1.64]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[67].texture}/>
                    </mesh>
                    <mesh
                        name="circle_d024"


                        geometry={nodes.circle_d024.geometry}
                        map={texture}
                        position={[1.471, -0.102, 1.741]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[68].texture}/>
                    </mesh>
                    <mesh
                        name="circle_e020"


                        geometry={nodes.circle_e020.geometry}
                        map={texture}
                        position={[1.19, -0.102, 1.596]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[69].texture}/>
                    </mesh>
                    <mesh
                        name="circle_e021"


                        geometry={nodes.circle_e021.geometry}
                        map={texture}
                        position={[1.54, -0.102, 2.046]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[70].texture}/>
                    </mesh>
                    <mesh
                        name="circle_e022"


                        geometry={nodes.circle_e022.geometry}
                        map={texture}
                        position={[-0.827, -0.102, 1.597]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[71].texture}/>
                    </mesh>
                    <mesh
                        name="circle_a008"


                        geometry={nodes.circle_a008.geometry}
                        map={texture}
                        position={[-0.602, -0.102, 2.884]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[72].texture}/>
                    </mesh>
                    <mesh
                        name="circle_b015"


                        geometry={nodes.circle_b015.geometry}
                        map={texture}
                        position={[0.192, -0.102, 3.042]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[73].texture}/>
                    </mesh>
                    <mesh
                        name="circle_b016"


                        geometry={nodes.circle_b016.geometry}
                        map={texture}
                        position={[0.913, -0.102, 3.04]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[74].texture}/>
                    </mesh>
                    <mesh
                        name="circle_c009"


                        geometry={nodes.circle_c009.geometry}
                        map={texture}
                        position={[-1.28, -0.102, 3.13]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[75].texture}/>
                    </mesh>
                    <mesh
                        name="circle_d025"
                        geometry={nodes.circle_d025.geometry}
                        map={texture}
                        position={[0.55, -0.102, 2.633]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[76].texture}/>
                    </mesh>
                    <mesh
                        name="circle_d026"


                        geometry={nodes.circle_d026.geometry}
                        map={texture}
                        position={[-1.481, -0.102, 2.729]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[77].texture}/>
                    </mesh>
                    <mesh
                        name="circle_d027"
                        geometry={nodes.circle_d027.geometry}
                        map={texture}
                        position={[1.518, -0.102, 2.788]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[94].texture}/>
                    </mesh>
                    <mesh
                        name="circle_d028"


                        geometry={nodes.circle_d028.geometry}
                        map={texture}
                        position={[1.443, -0.102, 3.145]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[78].texture}/>
                    </mesh>
                    <mesh
                        name="circle_e023"


                        geometry={nodes.circle_e023.geometry}
                        map={texture}
                        position={[-1.166, -0.102, 2.711]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[79].texture}/>
                    </mesh>
                    <mesh
                        name="circle_e024"


                        geometry={nodes.circle_e024.geometry}
                        map={texture}
                        position={[-0.077, -0.102, 2.623]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[80].texture}/>
                    </mesh>
                    <mesh
                        name="circle_e025"


                        geometry={nodes.circle_e025.geometry}
                        map={texture}
                        position={[1.227, -0.102, 2.658]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[81].texture}/>
                    </mesh>
                    <mesh
                        name="circle_b009"
                        geometry={nodes.circle_b009.geometry}
                        map={texture}
                        position={[1.288, -0.102, -0.114]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[82].texture}/>
                    </mesh>
                    <mesh
                        name="circle_a009"
                        geometry={nodes.circle_a009.geometry}
                        map={texture}
                        position={[0.465, -0.102, 3.909]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[83].texture}/>
                    </mesh>
                    <mesh
                        name="circle_b017"
                        geometry={nodes.circle_b017.geometry}
                        map={texture}
                        position={[-0.655, -0.102, 4.011]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[84].texture}/>
                    </mesh>
                    <mesh
                        name="circle_b018"
                        geometry={nodes.circle_b018.geometry}
                        map={texture}
                        position={[-1.354, -0.102, 3.831]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[85].texture}/>
                    </mesh>
                    <mesh
                        name="circle_c010"
                        geometry={nodes.circle_c010.geometry}
                        map={texture}
                        position={[1.16, -0.102, 4.104]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[86].texture}/>
                    </mesh>
                    <mesh
                        name="circle_d029"


                        geometry={nodes.circle_d029.geometry}
                        map={texture}
                        position={[-0.077, -0.102, 3.587]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[87].texture}/>
                    </mesh>
                    <mesh
                        name="circle_d030"


                        geometry={nodes.circle_d030.geometry}
                        map={texture}
                        position={[-0.097, -0.102, 4.193]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[88].texture}/>
                    </mesh>
                    <mesh
                        name="circle_d031"


                        geometry={nodes.circle_d031.geometry}
                        map={texture}
                        position={[1.049, -0.102, 3.664]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[89].texture}/>
                    </mesh>
                    <mesh
                        name="circle_d032"


                        geometry={nodes.circle_d032.geometry}
                        map={texture}
                        position={[1.549, -0.102, 3.872]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[90].texture}/>
                    </mesh>
                    <mesh
                        name="circle_e026"


                        geometry={nodes.circle_e026.geometry}
                        map={texture}
                        position={[-0.919, -0.102, 3.589]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[91].texture}/>
                    </mesh>
                    <mesh
                        name="circle_e027"


                        geometry={nodes.circle_e027.geometry}
                        map={texture}
                        position={[-0.394, -0.102, 3.587]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[92].texture}/>
                    </mesh>
                    <mesh
                        name="circle_e028"

                        geometry={nodes.circle_e028.geometry}
                        map={texture}
                        position={[-0.177, -0.102, 3.885]}
                    >
                        <FadeMesh color={"#ccffcc"} map={images[93].texture}/>
                    </mesh>
                    <mesh
                        name="circle_e029"


                        geometry={nodes.circle_e029.geometry}
                        position={[1.361, -0.102, 3.617]}
                    >
                        <FadeMesh color={"#ccffcc"} map={texture[95]} rotation={3.14}/>
                    </mesh>
                </group>
            </group>
        </group>
    );
}

useGLTF.preload("/screen_circles_v3.glb");


function Loader() {
    const {progress} = useProgress()
    return <Html center>{progress} % loaded</Html>
}

export const Screenview = ({}: {}) => {
    return (
        <div className="absolute top-0 left-0 w-[1920px] h-[4860px] overflow-hidden">
            <img style={{zIndex: 4}} className={"w-full absolute top-0 left-0"} src={mask}/>
            <video style={{zIndex: 2}} muted={true} autoPlay={true} loop={true} src={film}
                   className={"w-full absolute top-0 left-0"}/>
            <Canvas className="absolute top-0 left-0  z-999999 opacity-95" style={{zIndex: 3}}>
                <Suspense fallback={<Loader/>}>
                    {/*<Scene />*/}
                    <Model position={[0, 0, 0]} scale={1} rotation={[-Math.PI / 2, 0, Math.PI]}/>
                    <ambientLight intensity={1}/>
                </Suspense>
            </Canvas>
        </div>
    );
};

export default Screenview
