import * as React from "react";
import {Suspense, useEffect, useRef, useState} from "react";

import {Canvas, extend} from "@react-three/fiber";
import {
    CameraControls,
    Html,
    OrbitControls,
    PerformanceMonitor,
    PerspectiveCamera,
    useProgress
} from "@react-three/drei";
import {UnrealBloomPass} from 'three-stdlib'
import {OutputPass} from 'three/examples/jsm/postprocessing/OutputPass'
import {collection, collectionGroup, getFirestore, limit, orderBy, query, getCountFromServer} from "firebase/firestore";
import {useCollection} from 'react-firebase-hooks/firestore';
import {getRandomInt, transformUrlToBunny} from "./Screenview";
import {useAuth} from "reactfire";
import Web3DDNAFull from "../components/Web3DDNAScreenDisplay";

import film from "../../public/bg_loop_10-sec_v2.mp4?url"
import {getDatabase, onChildAdded, onChildChanged, ref} from "firebase/database";
import round from 'lodash/round'

import image from "../assets/DNA_merke.png?url"
import greenRing from "../assets/green_circle_02.png"
import {useTime} from "../hooks/useTime";

import "../assets/fonts/apotek1font.css"
import {TextureLoader} from "three";
import {useParams, useSearchParams} from "react-router-dom";

extend({UnrealBloomPass, OutputPass})


function Loader() {
    const {progress} = useProgress()
    return <Html center className={"w-screen text-center"}>
        <div className={"w-screen"}>{progress}% av DNA-et er laget</div>
    </Html>
}


function DNACanvas(props: { screenState: string, screenStateTimestamp: number, myImage: any, images: any[], debug: boolean }) {
    const [dpr, setDpr] = useState(1.5)

    useEffect(() => {
        console.log({dpr})
    }, [dpr]);

    return <Canvas dpr={dpr} className="absolute top-0 left-0 h-[100vh] z-100 w-full"
                   style={{zIndex: 3, height: "100vh"}}>
        <PerformanceMonitor onChange={({ factor }) => setDpr(round(0.5 + 1.5 * factor, 1))} />

        <Suspense fallback={<Loader/>}>
            <Web3DDNAFull
                debug={props.debug}
                screenState={props.screenState}
                screenStateTimestamp={props.screenStateTimestamp}
                freeForAll={true}
                //gltfFile="/dna_for_the_tv_v2.glb"
                mainImage={props.myImage || "https://apotek1-dna-images.b-cdn.net/images%2FScreenshot%202024-02-07%20at%2001.42.24.png?alt=media&width=500&crop_gravity=center&aspect_ratio=1000:999"}
                animate={true}
                useDefaultCamera={false}
                images={props.images}
                gltfFile={"/dna_rotation_action_v6.glb"}
                className={"min-w-full min-h-full object-cover"}/>
            <ambientLight intensity={1.5}/>

            {/*<RoundedBox />*/}
            {/*  <OrbitControls autoRotate={false}
                                   enableRotate
                                   enablePan={true}
                                   enableZoom={true}
                                   reverseOrbit={false}
                                   enableDamping={false}
                                   rotateSpeed={0.5}
                                   maxPolarAngle={Math.PI / 2}
                                   minPolarAngle={Math.PI / 2}
                                   dampingFactor={0.8}
                        //position={[123.964, 13.46, 0.637]}
                        //target={[123.964, 13.46, 0]}
                    />*/}
            {/*                    <PerspectiveCamera
                        makeDefault={true}
                        far={1000}
                        near={0.1}
                        fov={35}
                        position={[-50, 0, 0]}
                        rotation={[0, 0, 0]}
                    />*/}
        </Suspense>
    </Canvas>;
}

export const DNAWebReceiver = () => {
    const { screenId } = useParams();
    const [URLSearchParams] = useSearchParams()

    const [screenState, setScreenState] = useState("dontShowDNA")
    const [screenStateTimestamp, setScreenStateTimestamp] = useState(0)

    const [baloong1Time, setBalloon1Time] = useState<number>(new Date().getTime())
    const [baloong2Time, setBalloon2Time] = useState<number>(new Date().getTime())
    const [baloong3Time, setBalloon3Time] = useState<number>(new Date().getTime())
    const [baloong4Time, setBalloon4Time] = useState<number>(new Date().getTime())
    const ballonTimeDelay = 6000
    const [stateparams, setStateparams] = useState({})

    const db = getFirestore();
    const rtDb = getDatabase();
    const screenDBRef = ref(rtDb, 'screen')
    const auth = useAuth()

    useEffect(() => {
        if(URLSearchParams.has("pingback")) {
            setInterval(() => {
                const url = "https://ping.checklyhq.com/05f7a0d6-07fc-46ce-9444-a3bc53307c30"
                fetch(url, { mode: 'no-cors'}).then(response => console.log(response.ok && "ping ok"))
            }, 30000)
        }
    }, []);


    useEffect(() => {
        onChildChanged(screenDBRef, (snapshot) => {
            const data = snapshot.val();
            if (snapshot.key === screenId) {
                console.log({data, snapshot})
                setStateparams(data)
                setScreenState(data.state)
                setScreenStateTimestamp(data.stateTimestamp)
                setBalloon1Time(data.balloon1Time)
                setBalloon2Time(data.balloon2Time)
                setBalloon3Time(data.balloon3Time)
                setBalloon4Time(data.balloon4Time)
            }
        });
    }, [auth]);

    const getCurrentTime = () => {
        return new Date().getTime()
    }

    useEffect(() => {
        setInterval(() => {
            setBalloon1Time(() => getCurrentTime())
        }, 180000)
        setTimeout(() => {
            setInterval(() => {
                setBalloon2Time(() => getCurrentTime())
            }, 180000)
        }, 30000)
        setTimeout(() => {
            setInterval(() => {
                setBalloon3Time(() => getCurrentTime())
            }, 180000)
        }, 2*30000)
        setTimeout(() => {
            setInterval(() => {
                setBalloon4Time(() => getCurrentTime())
            }, 180000)
        }, 3*30000)
    }, []);


    const [myImage, setMyImage] = useState()
    const imagesRef = collection(db, '/images');
    const pledgesRef = collectionGroup(db, 'version');
    const q = query(imagesRef, orderBy("timestamp", 'desc'));
    const [count, setCount] = useState(1132)

    const [data] = useCollection(q);

    const [images, setImages] = useState([])
    const [allImages, setAllImages] = useState([])

    const time = useTime()

    const imgDBRef = ref(rtDb, 'images')
    const textureLoader = new TextureLoader();

    useEffect(() => {
        getCountFromServer(pledgesRef).then(_count => {setCount(_count.data().count)});
        setInterval(() => {
            getCountFromServer(pledgesRef).then(_count => {setCount(_count.data().count)});
        }, 30000)
    }, [auth]);



    useEffect(() => {
        onChildAdded(imgDBRef, (snapshot) => {
            const randomIndex = getRandomInt(images.length)
            const data = snapshot.val();

            if (data.timestamp > new Date().getTime()) {
                setImages((_images) => {
                    return [..._images.slice(0,randomIndex), {key: data.imageId, imageUrl: data.imageUrl, texture:  textureLoader.load(transformUrlToBunny(data.imageUrl, "100"))}, ..._images.slice(randomIndex+1)]
                })
                allImages.push(data.imageUrl)
            }

        });

    }, []);


    useEffect(() => {
        if (data) {
            var _allNewImagesFromDB = []
            data.docs.filter((doc) => {
                if (doc.data().hidden) {
                    console.log(doc.data())
                }
                return !(doc.data().hidden || doc.data().timestamp < 1707944486615);

            }).forEach((doc) => {
                    _allNewImagesFromDB.push(transformUrlToBunny(doc.data().imageUrl, "100"))
                })

            setAllImages(_allNewImagesFromDB)
            setImages(_allNewImagesFromDB.slice(0,760))
            }
    }, [data]);

    const loadRandomImage = () => {
        if (images.length > 100) {

            const randomImage = images[getRandomInt(images.length)]
            setImages((_images) => {

                const randomIndex = getRandomInt(_images.length)
                return [..._images.slice(0,randomIndex), transformUrlToBunny(randomImage, "150"), ..._images.slice(randomIndex+1)]
            })
        }
    }


    useEffect(() => {
        if (images.length > 100) {

            setTimeout(() => {

                setInterval(() => {

                    loadRandomImage()
                    loadRandomImage()
                    loadRandomImage()
                    loadRandomImage()
                    loadRandomImage()
                    loadRandomImage()
                    loadRandomImage()
                    loadRandomImage()
                    loadRandomImage()
                    loadRandomImage()

                }, 10000)

            }, 10000)

        }


    }, [allImages]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [data])

    if (!data) {
        return <div className="flex flex-col justify-center w-full text-[#737373] min-h-screen text-center">Henter
            bilder av kolleagene dine...</div>
    }

    return (
        <div
            className="flex flex-col justify-center text-[#737373] h-screen bg-black overflow-hidden pointer-events-none w-screen apotek1apex-Medium absolute top-0 left-0">
            {URLSearchParams.has("debug") && <div
                className={"w-[50vw] text-2xl text-black font-bold absolute bottom-0 p-6 bg-amber-200 z-[1000] h-80 whitespace-normal break-all m-2 rounded font-mono "}>
                {JSON.stringify(stateparams, null, 2)}


                <br/>
                Total images:{data.docs.length}, <br/>
                filtered images:{allImages.length}, <br/>
                imagesToDNA:{images.length} <br/>
                <br/>
                {new Date(baloong1Time).toTimeString()}<br/>
                {new Date(baloong2Time).toTimeString()}<br/>
                {new Date(baloong3Time).toTimeString()}<br/>
                {new Date(baloong4Time).toTimeString()}<br/>
            </div>}
            <div
                style={{transform: baloong1Time + ballonTimeDelay > time.getTime() ? "translateX(7vw)" : "translateX(40vw)"}}
                className={"w-[45vw] h-[45vw] modal-animated absolute top-12 z-[1002] -right-4"}>

                <img src={greenRing}
                     className={"w-full h-full absolute top-0 object-cover "}/>
                <div
                    className={" w-full h-full rounded-full bg-opacity-95 content-center align-middle text-center text-5xl text-white absolute "}>


                    <span style={{fontSize: "5vw"}}>
                        16.308.514
                    </span><br/>
                    <span style={{fontSize: "2.2vw"}}>
                    kundemøter hittil i år
                    </span>
                </div>
            </div>
            <div
                style={{transform: baloong2Time + ballonTimeDelay > time.getTime() ? "translateX(-5vw)" : "translateX(-40vw)"}}
                className={"w-[45vw] h-[45vw] modal-animated absolute bottom-12 z-[1002] left-2"}>
                <img src={greenRing}
                     className={"w-full h-full absolute top-0 object-cover "}/>
                <div
                    className={"  w-full h-full rounded-full bg-opacity-95 content-center align-middle text-center text-5xl text-white absolute"}>

                    <span style={{fontSize: "5vw"}}>
                        27.606
                    </span><br/>
                    <span style={{fontSize: "2.2vw"}}>
                    tilbakemeldinger i Maze
                    </span>
                </div>
            </div>
            <div
                style={{transform: baloong3Time + ballonTimeDelay > time.getTime() ? "translateX(-4vw)" : "translateX(-40vw)"}}
                className={"w-[45vw] h-[45vw] modal-animated absolute bottom-[43vh] z-[1002] -left-12"}>
                <img src={greenRing}
                     className={"w-full h-full absolute top-0 object-cover "}/>
                <div
                    className={" w-full h-full rounded-full bg-opacity-95 content-center align-middle text-center text-white absolute translate-y-[1vw]"}>
                    <span style={{fontSize: "5vw"}}>
                        90%
                    </span>
                        <br />
                    <span style={{fontSize: "2.2vw"}}>
                        Ambassadørgrad
                    </span>
                </div>
            </div>
            <div
                style={{transform: baloong4Time + ballonTimeDelay > time.getTime() ? "translateX(7vw)" : "translateX(40vw)"}}
                className={"w-[45vw] h-[45vw] modal-animated absolute bottom-[15vh] z-[1002] -right-4"}>
                <img src={greenRing}
                     className={"w-full h-full absolute top-0 object-cover "}/>
                <div
                    className={" w-full h-full rounded-full bg-opacity-95 content-center align-middle text-center text-5xl text-white z-10 absolute"}>
                      <span style={{fontSize: "5vw"}}>
                    {count - 29-103}
                    </span><br/>
                    <span style={{fontSize: "2.2vw"}}>
                     Registrerte 1'ere
                    </span>
                </div>
            </div>


            <img className={"w-1/5 m-12 mx-auto absolute top-12 left-12 z-[1002]"} src={image}/>
            <video style={{zIndex: 2}} muted={true} autoPlay={true} loop={true} src={film}
                   className={"w-full absolute opacity-80 top-0 left-0 h-screen bg-cover object-cover"}/>
            <DNACanvas debug={URLSearchParams.has("debug")} screenState={screenState} screenStateTimestamp={screenStateTimestamp} myImage={myImage}
                       images={images}/>
        </div>
    );
};


export default DNAWebReceiver
