import {useCollection, useDoc} from "@tatsuokaniwa/swr-firestore";
import {orderBy} from "firebase/firestore";
import {useMemo, useState} from "react";
import {AxisOptions, Chart} from "react-charts";
import {ErrorBoundary} from "react-error-boundary";
import {useRegisteredUsers} from "./components/useRegisteredUsers";
import {useSWRUsers} from "../../hooks/admin/useUsers";
import {LoadingApotekData} from "./LoadingApotekData";
import {useUnits} from "./components/useUnits";
import {useRegions} from "./components/useRegions";
import {Link} from "react-router-dom";
import {useUserPrDayLastNDays} from "./RegionsView";

export type AuthUser = {
    uid: string,
    phoneNumber: string,
    metadata: {
        creationTime: number
    }
}

const useUserSingleUser = (phoneNumber: string) => {
    return useDoc({
        path: `/users/${phoneNumber}`,
    })
}

function UserPreviewCard(props: { user: any, regions?: {}, units?: {} }) {
    const {data} = useUserSingleUser(props.user.phoneNumber.replace("+", ""))
    if (!data || !props.units) return null
    return <>
        <div className={"bg-gray-50 "}>{data && data.firstName + " " + data.lastName}</div>
        <div className={"bg-gray-50"}>{new Date(Date.parse(props.user.metadata.creationTime)).toLocaleString()}</div>
        <div className={"bg-gray-50 overflow-hidden"}>{props.user.phoneNumber}</div>
        <Link to={"/admin/units/" + data.unit} className={"bg-gray-50"}>{data && props.units[data.unit].name}</Link>

    </>;
}

function ImagePreviewCard(props: {
    user: { phoneNumber: string },
    image: { imageUrl: string; timestamp: number; userId: string; hidden?: boolean }
    unit: { unitName: string; regionName: string }
}) {
    return <>
        <div className={"bg-gray-50 "}><Link
            to={"/admin/users/" + props.user.id}>{props.user?.firstName + " " + props.user?.lastName}</Link></div>
        <div className={"bg-gray-50"}>{new Date(props.image.timestamp).toLocaleString()}</div>
        <div className={"bg-gray-50 overflow-hidden"}><img className={"w-full"} src={props.image.imageUrl}/></div>
        <div className={"bg-gray-50"}>{props.unit?.unitName}</div>
        {/*<div className={"bg-gray-50"}>{props.unit?.regionName}</div>*/}
    </>;
}

interface ButtonProps {
    label: string
}

function Button({label}: ButtonProps) {
    return <div
        className="ml-auto rounded bg-green-600 text-sm text-white font-bold px-2 py-1.5 hover:cursor-pointer hover:bg-green-500 ">{label}</div>;
}

Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

export function getDates(startDate, stopDate) {
    var dateArray = new Array();
    var currentDate = startDate;
    while (currentDate <= stopDate.addDays(1)) {
        dateArray.push(new Date(currentDate));
        currentDate = currentDate.addDays(1);
    }
    return dateArray;
}

const AdminDefaultView = () => {
    const {authUsers, usersByUserId} = useRegisteredUsers();
    const {usersById, users: dbUsers} = useSWRUsers();
    const {units} = useUnits()
    const {regions} = useRegions()


    const {data: images} = useCollection<{ imageUrl: string, timestamp: number, userId: string, hidden?: boolean }>({
        path: "images",
        queryConstraints: [
            orderBy("timestamp", "desc"),
        ],
    });

    const n = 10

    type Series = {
        label: string,
        data: []
    }

    const {usersPrDayCount: usersPrDay} = useUserPrDayLastNDays(authUsers, usersById, usersByUserId, dbUsers, new Date(2024, 1, 2, 0, 0, 0), new Date(Date.now()));

    const userPrDayTotal = useMemo(() => {
        const _usersPrDayTotal = {}
        Object.entries(usersPrDay).sort((a, b) => a[0].localeCompare(b[0]))
            .reduce((previousValue, currentValue, currentIndex, array) => {
                const total = previousValue + currentValue[1]
                _usersPrDayTotal[currentValue[0]] = total;
                return total
            }, 0)
        return _usersPrDayTotal;
    }, [usersPrDay]);

    console.log({userPrDayTotal});

    const chartDataActivity: Series[] = [
        {
            label: 'Brukerregistreringer',
            data: Object.keys(usersPrDay).map((key) => {
                return {
                    date: new Date(Date.parse(key)),
                    numberOfUsers: usersPrDay[key]
                }
            }) || []
        }
    ]

    const chartDataPercentage: Series[] = [
        {
            label: 'Brukerregistreringer',
            data: Object.keys(usersPrDay).map((key) => {
                return {
                    date: new Date(Date.parse(key)),
                    numberOfUsers: userPrDayTotal[key] / dbUsers?.length * 100
                }
            }) || []
        }
    ]

    const chartDataTotal: Series[] = [
        {
            label: 'Brukerregistreringer',
            data: Object.keys(usersPrDay).map((key) => {
                return {
                    date: new Date(Date.parse(key)),
                    numberOfUsers: userPrDayTotal[key]
                }
            }) || []
        }
    ]

    const primaryAxis = useMemo(
        (): AxisOptions<{ date: string, numberOfUsers: number }> => ({
            getValue: datum => datum.date,
            scaleType: "localTime",
        }),
        []
    )


    const secondaryAxes = useMemo(
        (): AxisOptions<{ date: string, numberOfUsers: number }>[] => [
            {
                getValue: (registrations) => {

                    return registrations.numberOfUsers || 0
                },
                elementType: 'line',
            },
        ],
        []
    )
    const [activeChartMode, setActiveChartMode] = useState<"percentage" | "total" | "activity">("percentage")


    if (!dbUsers || !authUsers || !units || !regions) {
        return <LoadingApotekData regions={regions} units={units}
                                  authUsers={authUsers} dbUsers={dbUsers}/>
    }


    return <div className={"grid flex-col "}>
        <h1 className="pb-20 text-4xl">Dashboard</h1>
        <div className={"flex"}><h1 className=" text-xl">Totaloversikt</h1>

        </div>

        <div className={"relative w-full bg-blue-50 p-6 my-6"}>
            <div
                className={"flex align-middle items-center text-sm mb-2 font-bold "}>
                <div
                    onClick={() => {
                        setActiveChartMode("total")
                    }}
                    className={" border-[1px] border-gray-300 hover:cursor-pointer hover:bg-blue-400 px-3 py-2 rounded-l-xl " + (activeChartMode === "total" ? " bg-blue-600 text-white" : "bg-white")}>Total
                </div>
                <div
                    onClick={() => {
                        setActiveChartMode("percentage")
                    }}
                    className={" border-[1px] border-gray-300 hover:cursor-pointer hover:bg-blue-400 px-3 py-2  " + (activeChartMode === "percentage" ? " bg-blue-600 text-white" : "bg-white")}>Prosent
                </div>
                <div
                    onClick={() => {
                        setActiveChartMode("activity")
                    }}
                    className={" border-[1px] border-gray-300 hover:cursor-pointer hover:bg-blue-400 px-3 py-2 rounded-r-xl " + (activeChartMode === "activity" ? " bg-blue-600 text-white" : "bg-white")}>Aktivitet
                </div>
            </div>
            <div className={"w-full relative h-[30vw] pb-6"}>


                <ErrorBoundary fallbackRender={(errorData) => {
                    return <>Oppsies<br/>{errorData.error.toString()}</>
                }}>

                    {authUsers && <Chart

                        options={{
                            data: activeChartMode === "total" ? chartDataTotal : activeChartMode === "activity" ? chartDataActivity : chartDataPercentage,
                            primaryAxis,
                            secondaryAxes,
                        }}
                    />}
                </ErrorBoundary>
            </div>
        </div>
        <div className={"flex"}><h1 className=" text-xl">Registreringer de siste 21 dagene:</h1></div>


        <div className="grid grid-cols-4 gap-1 text-sm">
            {authUsers?.users?.filter(user => new Date(user.metadata.creationTime) > new Date(Date.now() - 21 * 24 * 60 * 60 * 1000)).sort((a, b) => Date.parse(b.metadata.creationTime) - Date.parse(a.metadata.creationTime)).map((user) => {
                return <UserPreviewCard user={user} regions={regions} units={units}/>
            })}
        </div>

        <h1 className="pt-10 text-xl">Opplastede bilder</h1>
        De {n} siste innsendte
        <div className="grid grid-cols-4 gap-1 text-sm">

            {!!images && images.filter(image => !image.hidden && usersById[usersByUserId[image.userId].phoneNumber.replace("+", "")]).slice(0, n).map((image) => {
                return <ImagePreviewCard
                    user={{
                        userId: image.userId, ...usersById[usersByUserId[image.userId].phoneNumber.replace("+", "")],
                        //    ...usersByUserId[image.userId]
                    }}
                    image={image}
                    unit={{
                        unitName: units[usersById[usersByUserId[image.userId].phoneNumber?.replace("+", "")]?.unit]?.name,
                        regionName: regions[units[usersById[usersByUserId[image.userId].phoneNumber?.replace("+", "")]?.unit]?.region]?.name
                    }}
                />
            })}
        </div>

    </div>


}


export default AdminDefaultView
