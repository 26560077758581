import * as React from 'react';
import {ReactComponentElement} from 'react';

export const WideButton = ({label, onClick, id, className, disabled, buttonType = "button"}: {
    label: string | ReactComponentElement<any>;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    id: string,
    className?: string,
    disabled?: boolean,
    buttonType: "submit" | "button" | "reset"
}) => {
    return (
        <button type={buttonType} disabled={disabled} id={id} className={
            (disabled ? "bg-gray-300 " : "bg-blue-500 ") + " w-full hover:bg-blue-700 text-white font-bold py-2 px-4 rounded " + className
        } onClick={onClick}>
            {label}
        </button>
    );
};
