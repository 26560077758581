import * as React from "react";
import {Suspense, useEffect, useRef, useState} from "react";

import {Canvas, extend} from "@react-three/fiber";
import {Html, OrbitControls, PerspectiveCamera, RoundedBox, useProgress} from "@react-three/drei";
import {UnrealBloomPass} from 'three-stdlib'
import {OutputPass} from 'three/examples/jsm/postprocessing/OutputPass'
import Web3DDNA from "../components/Web3DDNA";
import {collection, getFirestore, limit, orderBy, query, startAt} from "firebase/firestore";
import {useCollection} from 'react-firebase-hooks/firestore';
import {transformUrlToBunny} from "./Screenview";


//const colors = new Array(size).fill().map(() => ["#000011","#044d35","#05165b","#02199b","#220e57"][Math.floor(Math.random() * 5)])

extend({UnrealBloomPass, OutputPass})


function Loader() {
    const {progress} = useProgress()
    return <Html center className={"w-full text-center"}>{progress}% av DNA-et er laget</Html>
}


const Personvern = () => {


    return (
        <div className="flex flex-col justify-center w-full text-white min-h-screen p-6">
            Vi bruker Cookies og informasjonskapsler på denne siden, de er som følgende:
            <ul className={"py-6"}>
                <li>_GRECAPTCHA</li>
                <li>OTZ</li>
                <li>__Secure-ENID</li>
                <li>SOCS</li>
                <li>AEC</li>
                <li>NID</li>
                <li>CONSENT</li>

            </ul>
            Disse er alle nødvendige for funksjonen til nettsiden, som er drevet av Google Firebase.<br />
            Vi bruker ikke Google Analytics.
        </div>
    );
};


export default Personvern
